body {
  font-family: Nunito-Regular;
}

.onboarding_text {
  height: 20px;
  width: 80%;
  color: #292929;
  font-family: Nunito;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 20px;
}
.onboarding_text_MFA {
  height: 20px;
  width: 80%;
  color: #292929;
  font-family: Nunito;
  font-size: 12px;
  letter-spacing: 0;
  margin-top: -2rem !important;
  line-height: 20px;
}
.Mangement_HeaderTotal {
  // margin-left: 14rem;
  display: flex;

  // justify-content: space-between;
}
.button {
  height: 44px;
  min-width: 138px;
  border-radius: 4px;
  color: #fff;
  text-align: center;
  background-color: #2947fd;
  padding: auto;
  cursor: pointer;
  box-shadow: 0 5px 8px -3px rgba(41, 71, 253, 0.5);
  padding-top: 4% !important;
  margin-left: 20px;
}


.Common_GridContent {
  margin-left: 13rem;
  margin-top: 2rem;
  // height: 45rem;
  width: 10%;
  text-align: start;
  color: white;

  &_GridItemStart {
    height: 64px;
    width: 134.84px;
    background-color: #2947fd;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    color: white;
    font-family: Nunito;
  }
  &_GridItemEnd {
    height: 64px;
    width: 134.84px;
    background-color: #2947fd;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: white !important;
    font-family: Nunito;
    padding-top: 0.6% !important;
  }
  &_GridItem {
    height: 64px;
    width: 134.84px;
    background-color: #2947fd;
    // border-radius: 4px;
    color: white;
    padding-top: 0.6% !important;
    font-family: Nunito;
  }
}
.Common_GridContentData {
  margin-left: 13rem;
  // height: 45rem;
  width: 10%;
  text-align: start;
  color: white;
  border-left: 1px solid grey;
  word-break: break-word;
  &_GridItem {
    min-height: 4rem;
    width: 8.43rem;
    word-break: break-word;
    // background-color: #2947FD;
    // border-radius: 4px;

    color: black;
    padding-top: 0.6% !important;
    font-family: Nunito;
  }
}

.GridItem_flex {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  //    align-content:center;
  padding-top: 7%;
}

.GridItem_flexheading {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  padding-top: 10%;
  //    height: 32px;
  //    width: 115.75px;
  font-family: Nunito;
  font-size: 12px;
  line-height: 14px;
}
.GridItem_flexitems {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  padding-top: 10%;
  font-family: Nunito;
  font-size: 14px;
  line-height: 14px;
}

.ManagementContent_Container {
  // height: 805px;
  border: 1px solid lavender !important;
  min-height: 51rem;
  height: 100%;
  width: 79%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  margin-left: 14rem;
  margin-top: 2rem;
  position: relative;
}

.ManagementContent_Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3%;
  margin-bottom: 10px;
}
.createUserButton {
  padding: 10px 55px;
  color: #081981;
  box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
  cursor: pointer;
}

.InputTextFeildCreateUsers {
  box-sizing: border-box;
  height: 2rem;
  // min-width: 25%;
  // width: 150%;
  border: 1px solid #dfe1e9;
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 10px;
}
.InputTextFeildCreateUsers:focus {
  box-sizing: border-box;
  height: 2rem;
  border: 1px solid grey !important;
  border-bottom: 1px solid grey !important;
  border-radius: 4px;
  outline: none;
}
.checkbox_container_userManagement {
}
.InputSelectFeild {
  box-sizing: border-box;
  height: 2rem;
  min-width: 25%;
  width: 65%;
  border: 1px solid #dfe1e9;
  border-radius: 4px;
  background-color: #ffffff;
  content: none;
  outline: none !important;
  padding-left: 10px;
}

.InputSelectFeild:before {
  display: none;
}

.InputSelectFeild:after {
  display: none;
}

.inputFieldNumber_container {
  height: 35px;
  width: 35px;
  background-color: #dfe1ea;
  text-align: center;
  border-radius: 50px;
  margin-right: 22%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.inputFieldNumber {
  color: #292929;
  font-family: Nunito-ExtraBold;
  font-size: 16px;
}

.InputField_flex {
  margin-left: 6%;
  padding-bottom: 35px;
}

.ManagementContent_Heading {
  height: 2.5rem;
  width: 36.625rem;
  color: #292929;
  font-family: Nunito-Bold;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 40px;
}
.managementFooter.editUser_footer {
  // width: 85% !important;
  // margin-left: 16%  !important;
  // padding-left: 55% !important;
  margin-left: 13rem !important;
  width: 34%;
  max-width: 82%;
}
.managementFooter {
  height: 90px;
  width: 79%;
  min-width: 20%;
  background-color: #ffffff;
  box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.3);
  margin-left: 14rem;
  display: flex;
  padding-left: 50%;
  padding-top: 1.5rem;
  align-items: center;

  .managementCancel {
    height: 2.75rem;
    width: 9.125rem;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 1px -2px 2px 1px rgba(0, 0, 0, 0.4);
    cursor: pointer;
    text-align: center;
    padding-top: 2.5%;
    margin-right: 12%;
  }

  .managementDone {
    height: 2.75rem;
    width: 9.125rem;
    border-radius: 4px;
    background-color: #2947fd;
    box-shadow: 0 5px 8px -3px rgba(41, 71, 253, 0.5);
    cursor: pointer;
    color: white;
    text-align: center;
    padding-top: 2.5%;
    margin-right: 14%;
  }
  .ddvManagementDone {
    height: 2.75rem;
    width: 9.125rem;
    border-radius: 4px;
    background-color: #2947fd;
    box-shadow: 0 5px 8px -3px rgba(41, 71, 253, 0.5);
    cursor: pointer;
    color: white;
    text-align: center;
    padding-top: 2.5%;
    margin-right: 14%;
  }
}

//checkbox for userManagement
.managementCheckbox[type="checkbox"]:before {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  border: 1px solid #808080;
  content: "";
  background: #fff;
}

.managementCheckbox[type="checkbox"]:after {
  position: relative;
  display: block;
  left: 0px;
  top: -50px;
  width: 50px;
  height: 50px;
  border-width: 1px;
  border-style: solid;
  border-color: #b3b3b3 #dcddde #dcddde #b3b3b3;
  content: "";
  background-image: linear-gradient(135deg, #b1b6be 0%, #fff 100%);
  background-repeat: no-repeat;
  background-position: center;
}

//styles for setPassword
.setPassword {
  border: 1px solid #dfe1e9;
  border-radius: 3px;
  width: 100%;
  min-width: 10% !important;
  margin-top: 1%;
  cursor: pointer !important;
  &:focus {
    border: 1px solid#706f7e;
  }
  &:hover {
    border: 1px solid#2947FD;
  }
}

.setUserPassword {
  margin-bottom: 2rem;
}

.setPasswordHeading {
  height: 24px;
  width: 120%;
  color: #292929;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 10%;
}

.setPasswordLabeLHeading {
  height: 1rem;
  width: 50%;
  color: #000000;
  font-family: Nunito;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1rem;
}

//styles for commonContentTab

//styles for creating the user
.managementFooter.createUserFooter {
  width: 29% !important;
}

//createRoles
.roles_input {
  box-sizing: border-box;
  height: 32px;
  width: 225px;
  border: 1px solid #dfe1e9;
  border-radius: 4px;
  background-color: #ffffff;
  outline: none;
  margin-left: 30px;
  margin-top: 25px;
  margin-bottom: 20px;
  padding-left: 1em;
}
.roles_input:active {
  box-sizing: border-box;
  height: 32px;
  width: 225px;
  border-radius: 4px;
}

.database-horizontal-line {
  top: 9px;
  right: 5px;
  position: relative;
  border: 1px solid #d3d5dc;
}

.ButtonGo.MuiButton-contained {
  height: 32px;
  width: 48px;
  min-width: 0px;
  text-align: center;
  margin: 23px 18px 22px;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  color: #081981;
  background-color: #FFFFFF;
  text-transform: none;
}
.map-view-img {
  width: 142px;
  height: 77px;
  background-color: #081981;
  margin-right: 22px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  div.nav-link {
    height: 32px;
    width: 100px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
  }
  span.text {
    color: #081981;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 16px;
    font-weight: 700;
  }
}

.global-loader-holder {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  text-align: center;
  box-sizing: border-box;
  z-index: 2;
  .loader {
    top: calc(50% - 20px);
    position: relative;
  }
  
}
.textAlignCenter{
  text-align: center;
  display: block;
}
.one-drive-buttons{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.oneDrive-status{
  display: flex;
  justify-content: space-between;
  width: 110px;
}

.one-drive-status-group{
  padding: 5px 0px;
  align-items: center;
  justify-content: space-between;
  display: flex;
}
.one-drive-button-style{
  margin-right: 10px !important; 
  text-transform: none !important; 
  margin-left: 12px !important; 
  margin-top: 10px !important;
  width: 140px !important;
  .MuiButton-label{
    white-space: nowrap;
  }
}
.drive-status-group{
  padding: 5px 0px;
  align-items: center;
  justify-content: space-between;
  display: flex;
  &.gDriveGroup {
    flex-direction: column;
    align-items: flex-start;
  }
}
.one-drive-button-style {
  .MuiButton-root {
    margin-right: 10px;
    text-transform: none;
    margin-left: 12px;
    margin-top: 10px;
    width: 140px;
  }

  .MuiButton-label {
    white-space: nowrap;
  }
}
.gDriveButton {
  .MuiButton-root {
    height: 32px;
    margin-left: 0px;
    margin-top: 0px;
    margin-right: 10px;
    text-transform: none;
    width: 140px;
  }
  .MuiButton-label{
    white-space: nowrap;
  }
}
.side-icons{
  margin-left: 10px;
}
.connection-group{
  display: block;
  &.graph-api{
    flex: 1;
    margin-left: 10px;
  }
  &.management-api{
    flex: 1;
    margin-left: 10px;
  }
  &.gDrive-api{
    flex: 1;
  }
}
.icon-status{
  &.connected{
    color: green;
  }
  &.not-connected{
    color: gray;
  }
  &.in-progress{
    color: #edb640
  }
  &.error{
    color: red;
  }
}
.status-group{
  margin-top: 29px;
}

.one-drive-status-icon{
  display: flex;
  &.not-error{
    align-items: center;
    padding: 18px;
    width: 180px;
  }
  &.error-occurred{
    align-items: center;
    margin-left: -10px;
    padding-top: 18px;
    width: 180px;
  }
  &.oneDriveStatus {
    align-items: center;
    margin-top: 10px;
  }
  &.gDriveStatus {
    align-items: center;
    margin-top: 15px;
  }
  &.dropbox {
    margin-top: 4%;
  }
}
.inspect-height {
  height: 280px;
  overflow-y: auto;
}
.one-drive-status-text{
  padding-left: 14px;
  width: 180px;
  overflow-wrap: break-word;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
}
.access-rights{
  display: flex;
  align-items: center;
}
.loader-data-source {
  position: absolute;
  margin-left: 40%;
  top: 40%;
  z-index:1;
  &.oneDrive-Table {
    margin-left: 44%;
    top: 59%;
    z-index: 10;
  }
  &.ntfs {
    margin-left: 44%;
    top: 59%;
    z-index: 100;
  }
  &.reports {
    margin-left: 38%;
    top: 67%;
    z-index: 10;
  }
  &.report-view {
    right: 50%;
  }
  &.resetPasswordLoader {
    margin-left: 16%;
    top: 55%;
    z-index: 10;
  }
  &.full-screen-view{
    margin-left: 50%;
    top: 50%;
  }
  &.dataOwner-Table {
    margin-left: 35%;
    top: 50%;
    z-index: 10;
  }
  &.tenantProfileLoader{
    top: 35%;
    margin-left: 35%;
  }
  &.riskPilot{
    margin-left: 50%;
  }
  &.tenantPlatform { 
    top: 75%;
    margin-left: 50%;
  }
}
.drive-name{
  max-width: 90%;
  overflow: hidden;
}

.errorInfo{
  margin: 84px 0px 0px 100px;
}

.oneDrive-formControlContainerSort{
  width: max-content;
  background: #DFE1E9;
  margin: 5px;
  border-radius: 3px;
  padding-top: 12px;
  padding-left: 10px;
}

.inspect-sort{
  margin-left: 320px;
  .inspect-sortAndFilter-button{
    color: #081981;
    background-color: transparent;
    font-size: 14px;
    font-weight: bold;
    text-transform: none;
  }
  .inspect-sortAndFilter-button:hover{
    background-color: transparent;
  }
}
.inspect-tabs{
  display: flex;
  align-items: center;
  margin-right: 30px;
  &.all-files-inspect{
    margin-right: 5px;
  }
  &.all-fields-inspect {
    margin-right: 25px;
  }
}
.inspect-change-view{
  display: flex;
  width: max-content;
  align-items: center;
  justify-content: center;
  color: rgb(8, 25, 129);
  background-color: transparent;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px .2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  border-radius: 5px;
  margin-left: 10px;
  &.switchView {
    border: 1px solid black;
  }
}
.inspect_toggler{
  height: 30px;
  border-radius: 16px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  box-shadow: inset 0 1px 2px 0.5px rgb(0 0 0 / 22%);
}
.dashboard_toggler {
  height: 40px;
  border-radius: 16px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  box-shadow: inset 0 1px 2px 0 rgb(0 0 0 / 22%);
  background-color: rgba(249, 249, 249, 1);
}
.dashboardNoDataImageContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  gap: 15px;
  margin-top: 10%;
}
.NoDataText {
  font-size: 18px;
  font-weight: 700;
}
.dashBoardToggler {
  display: flex;
  align-items: center;
  width: 100%;
  &.one {
    margin-left: 30%;
  }
  &.two {
    margin-left: 25%;
  }
  &.three {
    margin-left: 19%;
  }
  &.rest {
    margin-left: 15%;
  }

}
.inspect_toggler_active{
  height: 13px;
  border-radius: 22px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 8px -3px rgba(1,18,121,0.2);
  color:#2947fd;
  justify-content: center;
  align-items: center;
  padding: 6px 25px;
  margin-top: 2px;
  font-size: 12px;
}
.inspect_toggler_inactive{
  height: 20px;
  color: #706F7E;
  font-size: 12px;
  letter-spacing: 0;
  justify-content: center;
  align-items: center;
  padding: 6px 25px;

}
.back-drop-filter{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1
}
//User role widget styles 

.user-role-data{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px;
}
.userRoles-heading{
  font-size: larger;
  padding: 10px 0px;
}
.user-role-name{
  flex: 0.8;
}
.user-role-names-container{
  padding: 5px 0px;
}
.user-roles-legend{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  &.data-assets-widgets{
    justify-content: unset;
    gap: 20px;
  }
}
.user-role-group-name{
  margin-left: 5px;
  margin-right: 15px;
  flex: 1 1;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.user-role-widget{
  .MuiCardContent-root{
    padding: 0px;
  }
  &.MuiPaper-elevation1{
    box-shadow: none;
  }
}
.widget-styles{
  cursor: pointer;
}
.widget-styles:hover{
  box-shadow: 0px 0px 10px 2px rgba(0,0,0,0.2);
}
.user-roles-legendData{
  max-height: 200px;
  &.data-assets-widget{
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}
.user-role-text{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.data-assets-widget-count {
font-weight: 600;
font-size: 17px;
}
.user-toolTip-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tooltip-images{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 88px;
  &.oneDrive{
    width: 150px;
  }
  &.Dropbox {
    width: 150px;
    margin-left: 10px;
  }
}
.tooltip-header-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 400px;  
}
.data-owner-view-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  line-height: 19px;
  gap: 10px;
  font-family: 'Nunito-Regular';
}
.data-owner-text{
  font-size: 14px;
  color: #292929;
  font-weight: 700;
  &.total-count{
    font-size: 20px;
    margin-top: 8px;
  }
}
.header-popper{
  width: 230px;
  margin-left: 30px;
}
.heatMap-tooltip-title{
  margin-left: 15px;
  font-size: small;
  &.high{
    color: #FF2251;
  }
  &.medium{
    color: #FF8421
  }
  &.low{
    color: #081981
  }
  &.nonClassified{
    color: #5D0A90;
  }
}
.dataOwner-table-totalAlerts{
  display: flex;
  align-items: center;
  gap: 10px;
}
.dataOwner-table-count{
  margin-left: 5px;
  max-width: 51px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.dataOwnerTotalIcon {
  display: flex;
  align-items: center;
  .noUsersWithAlerts{
    display: flex;
    align-items: center;
  }
}

.main-heading-container{
  display: flex;
  border-top: 2px solid #8B8B8B;
  border-bottom: 2px solid #8B8B8B;
}
.main-header-name{
  text-align: center;
  &.dataSource{
    flex: 0.159;
  }
  &.added{
    flex: 0.09;
  }
  &.sensitivity{
    flex: 0.205;
  }
  &.alerts{
    flex: 0.127;
  }
  &.categories{
    flex: 0.14;
  }
  &.flaggedUsers{
    flex: 0.138;
  }
  &.activities{
    flex: 0.06;
  }
  &.asOn{
    flex: 0.06
  }
}

.dataOwner-icons{
  width: 40px;
  margin-left: 20px;
  &.ONEDRIVE{
    width: 70px;
    margin-left: 0px;
  }
  &.POSTGRES{
    width: 45px;
    margin-left: 15px;
  }
  &.DROPBOX{
    width: 34px;
    margin-left: 17px;
  }
}
.userAlerts {
  display: flex;
  align-items: center;
  margin-left: 25px;
  margin-top: 10px;
}
.userAlertsCount {
  display: flex;
  align-items: center;
}
.highUser{
  color: #FF2251;
}
.mediumUser{
  color: #FF8421;
}
.lowUser{
  color: #081981;
}
.dataOwnerUserIcons{
  display: flex;
  gap: 5px;
  align-items: center;
  margin-left: 15px;
}
.dataOwnerSummaryHeader{
  margin-left: 20px;
  margin-top: 25px;
  font-size: 24px;
  font-weight: 700;
}
.dataOwnerDate{
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-family: 'Nunito-Regular';
  font-weight: 700;
}
.dataOwnerPieLegend {
  display: flex;
  align-items: center;
  gap: 10px;
}
.pieDataOwner{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.dataOwnerCount{
  display: flex;
  align-items: center;
  gap: 10px;
}
.dataOwnerPieCount {
  max-width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
}

//toggle switch styles
.tabSwitch{
  display: flex;
  align-items: center;
}
.toggle_switch_name{
  font-size: 14px;
  &.active {
    font-weight: 700;
    font-size: 15px;
  }
}
.toggle_switch_count {
  font-size: 14px;
  margin-left: 10px;
  &.active {
    font-weight: 700;
    font-size: 18px;
  }
}
.toggle_switch {
  display: flex;
}
.tab_switch_heading {
  display: flex;
  padding: 8px;
  gap: 20px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 3px;
  white-space: nowrap;
}
.tab_switch_heading_name {
  font-size: 20px;
  font-weight: 700;
  padding-left: 15px;
}
.tab_switch_heading_count {
  font-size: 20px;
  font-weight: 700;
  color: #2947FD;
  padding-right: 15px;
}
.dropBox {
  display: flex;
  height: 700px;
  width: 1200px;
  margin-top: 20px;
  margin-left: 10px;
}
.dropBoxButtons {
  display: flex;
  align-items: center;
}

.tenantProfile{
  width: 350px;
  height: 310px;
  border-radius: 4px;
  background: #081981;
  box-shadow: 0px 25px 40px 0px rgba(0, 0, 0, 0.60);
  color: white;
  position: relative;
}
.tenantProfilePopper{
  top: 80px !important;
  left: 200px !important;
  z-index: 5;
}
.editTenant{
  height: 20px;
  width: 20px;
  float: right;
  padding: 2px;
  margin-right: 15px;
  margin-left: 15px;
  cursor: pointer;
}
.profileHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .profileName {
    height: 18px;
    font-size: 16px;
    font-weight: 700;
    margin: 16px 10px 10px 22px;
  }
  .closeIcon {
    height: 20px;
    margin-top: 10px;
    margin-right: 14px;
    cursor: pointer;
  }
}
.tenantEditFields{
  padding: 20px 18px;
  .MuiButtonBase-root{
    height: 22px;
    width: 78px;
    font-size: 13px;
    font-weight: 700;
    background-color: white;
    margin: 24px 16px 24px 0px;
    text-transform: none;
  }
  .MuiButtonBase-root:hover{
    background-color: white;
  }
}
.tenantEditInput{
  display: flex;
  align-items: center;
  .editTenant{
    cursor: auto;
  }
}
.tenant-inputField{
  height: 30px;
  width: 270px;
  background-color: white;
  padding: 5px;
  border-radius: 4px;
}
.tenantLogo{
  display: flex;
  align-items: center;
  gap: 24px;
  .userName{
    height: 84px;
    width: 84px;
    padding: 0px;
    font-size: 40px;
    cursor: pointer;
  }
  .tenantLogoContainer{
    height: 84px;
    width: 84px;
    cursor: pointer;
  }
  .tenantProfileLogo{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: white;
    font-size: 30px;
  }
  .editPicture{
    text-decoration: underline;
    cursor: pointer;
  }
}
.tenantProfileLogoBar-container{
  height: 52px;
  width: 52px;
  margin-right: 16px;
  .userName{
    height: 100%;
    width: 100%;
    font-size: 24px;
    padding: 0;
  }
}
.tenantProfileLogoBar {
  height: 100%;
  width: 100%;
  font-size: 20px;
  border-radius: 50%;
  background-color: white;
}
.error-snackbar {
  position: absolute !important;
  top: 2% !important;
  right: 2% !important;
}
.dataSource-name-icon{
  display: flex;
  align-items: center;
  .dataSource-icon{
    height: 32px;
    margin-left: 5px;
  }
  .dataSource-icon-img{
    height: 32px;
    width: 30px;
  }
  &.location {
    gap: 5px;
  }
}
.noDataAvailable{
  width: 100%;
  height: 300px;
}

.deleteIconImg{
  height: 24px;
  width: 22px;
}
.selectedSourceName{
  font-size: 14px;
  font-weight: bold;
  line-height: 16px;
  margin: 10px 0px;
}
.confirmationDialog {
  font-size: 14px;
  .dialogHeader {
    h2 {
      font-weight: bold;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;  
      img {
        width: 16px;
      }
      .closeIconButton{
        float: right;
        max-width: 16px;
        min-width: 16px;
      }
    }  
  }
  .MuiPaper-root {
    height: 200px;
    width: 500px;
    border: 1px solid #081981;
    padding: 24px;
  }
  .MuiDialogContent-root {
    padding: 0;
  }
  .MuiDialogActions-root{
    padding: 0;
  }
  .MuiDialogTitle-root {
    padding: 0;
  }
  .criteriaFailMessage{
    font-size: 18px;
    color: red;
  }
  .loader{
    z-index: 3;
  }
}
.dialogCancel {
  width: 124px !important;
  height: 32px !important;
  box-shadow: 0 5px 8px -3px rgb(1 18 121 / 50%);
}
.dialogConfirm {
  color: #ffffff !important;
  background-color: #081981 !important;
  margin-bottom: 0px !important;
  cursor: pointer;
  width: 124px !important;
  height: 32px !important;
  &.Mui-disabled{
    background-color: rgba(138, 141, 147, 1) !important;
  }
}
.confirmSourceName{
  display: flex;
  align-items: center;
  margin: 10px 0px;
  gap: 10px;
}
.inputSourceName{
  width: 250px;
}
.dialogConfirmMessage{
  display: flex;
  align-items: center;
  gap: 5px;
  color: green;
}
//Switchable tabs

.switchableTabs{
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  overflow-y: auto;

  header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorDefault.MuiPaper-elevation4 {
    border-radius: 22px;
    background-color: white;
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    width: fit-content;
    margin: auto;

    .MuiTabs-root {
      max-height: 32px;
      min-height: 32px;
    }

    .MuiTab-root {
      max-height: 28px;
      min-height: 28px;
      align-self: center;
    }

    .PrivateTabIndicator-colorPrimary-18 {
      background-color: transparent
    }
  }

  .MuiTab-textColorPrimary.Mui-selected {
    border-radius: 22px;
    box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
    transform: rotate(-360deg);
    color: #2947FD;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    height: 32px;
  }
}
.direct-data-table-head{
  width: 12%;
}
.access-rights-table-head{
  width: 15%;
}
.account-status-table-head{
  width: 10%;
}
.desiredPort{
  display: flex;
}
.errorInstance{
  color: red;
}
//styles for system summary
.systemSummary-text {
  &.active {
    height: 20px;
    border-radius: 22px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 8px -3px rgba(1,18,121,0.5);
    color:#2947fd;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 9px 50px;
    margin-top: 1px;
    font-size: 20px;
    font-weight: 700;
  }
  &.inactive {
    height: 20px;
    color: #706F7E;
    font-size: 12px;
    letter-spacing: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 50px;
    font-size: 20px;
  }
}
.dataOwnerTitles {
  font-size: 14px;
  font-weight: 700;
}
.selectiveUserContainer{
  padding-bottom: 10px;
}
.selectiveUsers {
  display: flex;
  align-items: center;
  gap: 30px;
  &.platformNTFS {
    gap: 15.5rem;
  }
}
.noOfUsersContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.selectiveUserDisabled{
  color: #d3d3d3;
}
.selectiveUserFiled{
  background: #dddddd;
}
.selectiveUserText{
  white-space: nowrap;
  &.platformNTFS{
    font-size: 15px;
    font-weight: 700;
  }
}
.noPermissionView {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-transform: capitalize;
  margin-left: 10%;
  color: gray;
}
.DataOwnerHelpIcon{
  margin-top: 10px;
}

//Reset password error message styles
.passwordErrorMessage {
  padding: 0px;
  color: red;
  &.userEditingScreen{
    margin-left: 3%;
  }
  &.resetPasswordScreen{
    margin-left: 6%;
  }
}
.dataSourceUserFilter{
  box-shadow: none !important;
}

.serverIconContainer{
  display: flex;
  gap: 10%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.iconsContainer {
  display: flex;
  flex-direction: column;
  gap: 13px;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
}

.sourcesIcons{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  width: 100px;
  height: 60px;
  border-radius: 5px;
  margin-left: 10%;
  &.iconNotSelected {
    border: 2px solid #C3C4CA;
  }
  &.iconSelected {
    border: 2px solid #2947FD;
  }
}

.IconTitle {
  display: flex;
  align-items: baseline;
  gap: 10px;
  font-family: 'Nunito-Regular';
  font-weight: 600;
  &.cloudSource {
    margin-left: 15px;
  }
  margin-left: 4px;
}

.logConfigurationContainer {
  display: flex;
  gap: 30px;
  margin-top: 10px;
}
.logConfigurationDoneContainer{
  display: flex;
  align-items: center;
  gap: 25px;
  border: 2px solid #69C0FF;
  margin-top: 20px;
  margin-bottom: 30px;
  padding: 5px;
  border-radius: 5px;
  background-color: #CEEAFF;
}
.logSourceConfigDone .MuiCheckbox-colorPrimary.Mui-checked {
  color: #2947FD;
}

.confirmContainer{
  padding-left: 2%;
}
.confirmContainer .MuiTypography-body1 {
  font-size: 14px;
}
.WinlogbeatContainer{
  display: flex;
  align-items: center;
}
.winlogbeatText{
  font-size: 14px;
  margin-left: 5px;
  color: #2947FD;
  text-decoration: underline;
  cursor: pointer;
}

.winlogbeatTitleText{
  color:#081981;
  white-space: nowrap;
}
.winlogbeatHeadingTextContainer{
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.logSourceContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border: 2px dashed #979696;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 25px;
  border-radius: 3px;
}
.logSourceMessageContainer{
  color: #858080;
}
.logSourceContainerHeader{
  margin-top: 20px;
  margin-bottom: 15px;
}
.addLogSourceText{
  margin-bottom: 5px;
}
.logSourceVerificationContainer{
  height: 51px;
}
.logSourceButtonContainer{
  display: flex;
  align-items: center;
}
.winlogbeatCloseButton .MuiButton-root {
  margin-bottom: 4rem;
}

.dataSourceCloseButton .MuiButton-root {
  margin-top: 1rem;
}
.Devops_popUp_Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -3rem;
  margin-left: 20%;

  &.dataSource {
    margin-left: 52%;
    margin-bottom: 5%;
  }
}

.logSourceDsContainer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.dataSourceButtonsContainer {
  display: flex;
  gap: 14px;
  &.gDriveButtonContainer {
    align-items: baseline;
  }
}

.inactivityPolicyUsername{
  color: #081981;
}
.header-icon{
  display: flex;
  align-items: center;
  gap: 5px;
  &.history-time{
    align-items: flex-start;
  }
}

.disabledReportCursor {
  cursor: not-allowed;
}

.toleranceLevelContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.dashed-line {
  border-top: 2px dashed #979797;
  margin: 15px 0;
  position: absolute;
  width: calc(100% - 20px);
}

.toleranceContainer {
  position: relative;
}

.toleranceImagesContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  cursor: pointer;
}

.toleranceItem {
  position: relative;
}
.toleranceText{
  display: flex;
  align-items: center;
  margin-top: 8px;
  gap: 10px;
}
.toleranceLevelTextContainer{
  font-size: 14px;
  &.HIGHTolerance{
    font-weight: 600;
    color:#FA0639;
  }
  &.MEDIUMTolerance{
    font-weight: 600;
    color: #ED8A43
  }
  &.LOWTolerance{
    font-weight: 600;
    color: #162660
  }
}
.triggerContainerText{
  font-weight: 600;
}

.toleranceLevelInfoContainer{
  display: flex;
  align-items: center;
  gap: 5px;
}
.toleranceLevelInformationText{
  font-size: 11.7px;
}
.toleranceLevelInfoContainers{
  margin-bottom: 16px;
}
.triggerRuleText{
  font-size: 14px;
  font-weight: 600;
  margin-left: 10px;
}
.alertsTableDisable {
  opacity: 0.5;
  cursor: not-allowed;
}
.postgresOnIconImg {
  width: 80%;
  margin-top: 8px;
  height: 100%;
  margin-left: 10px;
}
.postgresAzureIconImg{
  width: 70%;
  height: 100%;
  margin-top: 4%;
  margin-left: 16%;
}

.oneDriveConnectionIconContainer {
  display: flex;
  align-items: center;
  &.gDriveIconContainer{
    flex-direction: row-reverse;
    gap: 10px;
  }
}

.gDriveButtonMainContainer {
  display: flex;
  align-items: end;
  gap: 3px;
}

.mdrNavToolBarContainer {
  .MuiToolbar-regular{
    min-height: 77px;
  }
}
.mdrNavToolBar{
  background: #5D0A90;
}
.mousePointer {
  cursor: pointer;
}
.emailNotification {
  &.active {
    color: #4CAF50;
  }
  &.inactive {
    color: #BDBDBD;
  }
}

.sensitivityCategory {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dataMapNotificationBadge {
  position: fixed;
  width: 25px;
  height: 25px;
}
.location-accordion {
  .MuiPaper-elevation1 {
    box-shadow: none;
  }
}

.location-details-container {
  min-height: 350px;
  min-width: 225px;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  overflow: auto;
  height: 240px;
}
.gov-full-logo{
  width: 72px;
  margin-left: 2px;
  margin-top: 10px;
  cursor: pointer;
}