.MDRInputTextFeildAddConfig {
  box-sizing: border-box;
  height: 2.5rem;
  min-width: 15%;
  width: 90%;
  border: 1px solid #dfe1e9;
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 5px;

}
.MDRInputTextFeildAddConfig:focus {
  box-sizing: border-box;
  height: 2.5rem;
  border: 1px solid grey !important;
  border-bottom: 1px solid grey !important;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
}
input[type="text"]:focus,
input[type="number"]:focus,
textarea:focus {
  font-size: 16px;
}
.MDRAutocomplete {
  height: 2rem;
  width: 90%;
  padding: 0 !important;
  margin-bottom: 0.6rem !important;
  box-sizing: border-box;
}

// .MDRContainerInputFields {
//   .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
//     // padding: 1px !important;
//   }
// }
.MDRFormcontrol {
  label {
    font-size: 1rem;
    font-family: "Nunito-Regular";
  }
}
.MDR_SettingsContent_Container {
  border: 1px solid lavender !important;
  min-height: 50rem;
  height: 100%;
  width: 83%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  margin-left: 13rem;
  margin-top: 2rem;
  position: relative;

  .MDRInputField_flex {

    padding-bottom: 35px;
  }
}

.MDRinputFieldNumber_container {
  height: 35px;
  width: 35px;
  background-color: #dfe1ea;
  text-align: center;
  border-radius: 50px;
  margin-left: 20%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.MDRinputFieldNumber {
  color: #292929;
  font-family: Nunito-ExtraBold;
  font-size: 16px;
}

.MuiGrid-container{
  margin-top: 25px;
  padding-right: 20px;
 }
