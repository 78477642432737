.create-data-source-container{
    // height: 805px;
    border:1px solid lavender !important;
    min-height: 30rem;
    height:100%;
    border-radius: 4px;
    width:calc(100% - 350px);
    background-color: #FFFFFF;
    box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.2);
    margin-left: 14rem;
    margin-top:2rem;
    position: relative;
    padding:10px;
    padding-left: 23px;
    .create-data-source-heading{
        height: 2.5rem;
        color: #292929;
        font-family: Nunito-Bold;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 40px;
        padding-bottom: 14px;
    }
    .MuiOutlinedInput-input {
        padding: 10.5px 14px;
    }
}