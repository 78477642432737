.locationSliderText{
    font-size: 14px;
    margin-left: 10px;
    &.High{
      font-weight: 600;
      color:#FA0639;
    }
    &.Medium{
      font-weight: 600;
      color: #ED8A43
    }
    &.Low{
      font-weight: 600;
      color: #162660
    }
  }
  .locationSlider {
    margin-left: 10px;
  }
  
  .locationSliderContainer {
    padding: 5px;
  }
  .locationLevelInfoContainer {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: 10px;
  }
  /* Styling for the slider container */
  .locationSliderContainer {
    width: 100%;
    margin: 30px auto;
    position: relative;
  }
  
  /* Basic styling for the slider */
  .horizontal-slider {
    height: 8px;
    background-color: #2947fd;
    border-radius: 4px;
    position: relative;
  }
  
  /* Thumb styling */
  .slider-thumb {
    height: 24px;
    width: 24px;
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    cursor: grab;
    top: -8px;
  }
  
  .slider-thumb.H {
    background-color: #fa0639;
  }
  .slider-thumb.M {
    background-color: #ed8a43;
  }
  .slider-thumb.L {
    background-color: #162660;
  }
  
  .horizontal-slider .slider-mark-active {
    background-color: #f00; /* Red color for active mark */
  }
  .example-mark {
    position: absolute;
    left: 0px;
    width: 8px;
    height: 8px;
    border: 2px solid rgb(0, 0, 0);
    background-color: rgb(255, 255, 255);
    cursor: pointer;
    border-radius: 50%;
    vertical-align: middle;
  }
  
  .horizontal-slider .example-mark {
    margin: 0px calc(19px);
    bottom: calc(50% - 6px);
  }
  
  /* Ensure the mark's position aligns correctly with the slider track */
  .horizontal-slider .slider-mark {
    position: absolute;
    bottom: -8px;
    width: 2px;
    height: 12px;
    background-color: #000;
  }
  
  /* Style for the mark label */
  .horizontal-slider .slider-mark-label {
    position: absolute;
    bottom: -25px; /* Space below the slider for the label */
    font-size: 12px;
    text-align: center;
    width: 50px;
    margin-left: -25px; /* Center the label relative to the mark */
    color: #333;
  }
  
  /* Slider label colors */
  .slider-label {
    font-size: 12px;
    user-select: none;
  }
  
  .slider-label.H {
    background-color: #fa0639;
  }
  
  .slider-label.M {
    background-color: #ed8a43;
  }
  
  .slider-label.L {
    background-color: #162660;
  }
  
  /* Marker styling */
  .slider-mark {
    position: absolute;
    bottom: 0;
    width: 2px;
    height: 24px; /* Tall marker line */
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Dot above the mark */
  .slider-mark-dot {
    width: 12px;
    height: 12px;
    background-color: #f0a500; /* Yellow color similar to your image */
    border-radius: 50%;
    margin-bottom: 4px; /* Space between dot and mark line */
  }
  
  /* Mark label below the slider */
  .slider-mark-label {
    font-size: 12px;
    color: #333;
    margin-top: 4px;
    text-align: center;
    width: 50px;
    margin-left: -25px; /* Center the label */
  }