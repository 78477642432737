
.level{
    height: 20px;
    font-family: $font-family-base;
    font-size: 12px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: 1px;
    text-align: center;
    &.status{
        font-weight: 700 !important;
    }
    &.risk{
        font-weight: 700 !important;
    }
}
.bg-color{
    width: max-content;
    height: 23px;
    padding: 3px 6px 0px 7px;
    border-radius: 2px;
    &.status{
        width: fit-content !important;
    }
}
#unresolved{
    @include filter-color(ok);
}
#resolved{
    @include filter-color('inactive');
}
#high{
    @include filter-color(high);
}
#medium{
    @include filter-color(med);
}
#low{
    @include filter-color(low);
}
#non\ classified{
    @include filter-color(not_processed)
}
#active{
    @include filter-color(ok);
}
#draft{
    @include filter-color(draft);
}
#inactive{
    @include filter-color('inactive');
}
#deleted{
    @include filter-color('inactive');
}
#disabled{
    @include filter-color('disabled');
}
#count{
    @include filter-color(alertCount);
}