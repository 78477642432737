.main-panel {
  background: none;
}

.netspace-wrapper {
  .a-tag-none {
    text-decoration: none;
    color: inherit;
  }

  .txt-underline {
    text-decoration: underline;
  }

  .HIGH,
  .NO {
    color: #FF2251
  }

  .MEDIUM,
  .UNKNOWN {
    color: #FF8429
  }

  .LOW,
  .YES {
    color: #081981
  }

  .mrg-left-8{
    margin-left: 8px;
  }

  .edit-nmap {
    cursor: pointer;
  }

  .InputTextFeild{
    background-color: #F0F0F0;
      input { color: #333333; }
      label { color: #333333; }
      & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
        border-color: #6200EE;
      }
      & .Mui-focused {
        color: #6200EE;
      }
  }

  .file-upload-title {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 37%;
    height: 100px;
  }

  .input-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    width: 100%;
    height: 100px;
    .input-child {
      flex: 0 0 20%;
      &.long-width{
        flex: 0 0 40%;
      }
      &.short-width{
        flex: 0 0 5%;
      }
      input[type=file] {
        max-width: 195px;
      }
    }
  }

  .netspace-header {
    align-items: center;
    background: linear-gradient(rgba(241, 242, 246, 0), #dfe1e9);
    display: flex;
    font-family: Nunito-Regular;
    justify-content: space-between;
    padding-left: 210px;

    h2 {
      margin: 0;
      font-size: 32px;
    }
  }

  .netspace-body {
    margin: 0 0 24px;
    padding-left: 210px;

    .input-container {
      background-color: #F1E9F6;
    }

    .control-panel {
      align-items: center;
      display: flex;
      gap: 15px;
      justify-content: space-between;
      font-weight: bold;
      margin-bottom: 10px;
      width: 100%;

      .header-count-item-label {
        font-weight: bold;
      }

      .header-counts {
        align-items: center;
        display: flex;
        width: 50%;
        overflow: auto;
      }

      .header-count-item {
        align-items: center;
        display: flex;
        gap: 3px;
        margin-left: 15px;
        cursor: pointer;
      }

      .header-legend {
        .item {
          margin-left: 10px;
        }

        .legend-img {
          width: 15px;
        }
      }
    }

    .summary-parent {
      display: flex;
      align-items: center;
      background-color: #ffff;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 60px;

      .netspace-summary-header {
        margin-top: 25px;
        font-size: 24px;
        font-weight: 700;
      }

      .summary-grid-container {
        width: 100%;

        .card-content {
          margin-left: 20px;
        }

        .grid-margin-top-0 {
          margin-top: 0px;
        }

        .grid-top-pad-0 {
          padding-top: 0px;
        }

        .radial-chart-container {
          margin-top: -75px;
          margin-left: -45px;
          width: 200px;
          height: 172px;
        }
      }
    }

    .netspace-tbl-container {
      .site-map-wrapper {
        .icon-status {
          &.connected {
            color: #081981;
          }
        }

        .pieChart-container {
          margin-top: -75px;
          margin-left: -45px;
          width: 175px;
          height: 300px;
          max-width: 55%;
          flex-basis: 55%;
        }

        .port-header {
          gap: 5px;
        }

        .visibility-icon {
          display: inline-block;
        }

        .labelTxt {
          position: relative;
          top: -5px;
        }

        .siteMapSensitivePortContainer {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .summary-grid-container {
          width: 100%
        }

        .hyperlink {
          color: #357BE2
        }

        .chip-container.inspect-content.ports {
          width: min-content;
        }

        .a-tag-none {
          text-decoration: none;
          color: inherit;
        }

        .txt-underline {
          text-decoration: underline;
        }

        .QuestionMark {
          color: #FF8429;
        }

        .dataOwnerTitles {
          text-align: right;
        }

        .criticality-title {
          font-size: 14px;
          font-weight: 700;
          padding: 10px;
        }

        .dataOwnerUserIcons {
          cursor: pointer;

          &.false {
            opacity: 0.5;
          }
        }

        .not-monitored {
          color: #FF2251
        }

        .site-map-header {
          font-size: 1.5em;
          font-weight: bold;
          color: #333;
          font-family: Nunito-Regular;
          -webkit-font-smoothing: antialiased;
          font-size: 16px;
        }

        .MuiTypography-root.MuiTypography-body1 {
          width: 100%;
        }

        .selectiveUsers {
          gap: 15px;
          justify-content: space-between;
        }



        .dataOwnerSensitive:last-child {
          display: none;
        }

        .css-qvtrhg-MuiDataGrid-virtualScroller {
          overflow-x: hidden;
        }

        .mrg-5 {
          margin-left: 5px;
          margin-right: 15px;
        }

        .site-map-table-row {
          background-color: #F1E9F6;
        }

        .dataOwnerSummaryHeader {
          margin-top: 0px;
          font-size: 1.5rem;
          margin-left: 10px;
        }

        #group-by-select {
          font-size: 1.5rem;
        }

        .header-total-host {
          font-size: 1.5rem;
        }

        .inspect-title-right.dataOwner {
          margin-top: 0px;
        }

        .inspect-title-header.dataOwner {
          margin-bottom: 5px;
        }

        .inspect-file-fieldName.dataOwner {
          display: block;
          width: 70%;

          span.inspect-file-fieldName.dataOwner {
            display: inline-block;
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        .table-utility {
          margin: 5px;
          font-size: 1.5rem;
          margin-top: 35px;
        }

        .normal-color {
          color: black;
        }
      }

      .netspace-tbl {
        width: 100%;

        .MuiDataGrid-columnHeaders {
          background: #C4A3D4;
          color: white;
          font-weight: bold;
        }

        .squareBadge.other.tag {
          background-color: #8000BD;
        }

        .site-map-table-row {
          background-color: #F1E9F6;
          cursor: pointer;
        }

        .chip-container.inspect-content.ports {
          width: min-content;
        }
      }
    }
  }
}

.site-map-wrapper {
  .icon-status {
    &.connected {
      color: #081981;
    }
  }

  .pieChart-container {
    margin-top: -75px;
    margin-left: -45px;
    width: 175px;
    height: 300px;
    max-width: 55%;
    flex-basis: 55%;
  }

  .port-header {
    gap: 5px;
  }

  .visibility-icon {
    display: inline-block;
  }

  .labelTxt {
    position: relative;
    top: -5px;
  }

  .siteMapSensitivePortContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .summary-grid-container {
    width: 100%
  }

  .hyperlink {
    color: #357BE2
  }

  .chip-container.inspect-content.ports {
    width: min-content;
  }

  .a-tag-none {
    text-decoration: none;
    color: inherit;
  }

  .txt-underline {
    text-decoration: underline;
  }

  .QuestionMark {
    color: #FF8429;
  }

  .dataOwnerTitles {
    text-align: right;
  }

  .criticality-title {
    font-size: 14px;
    font-weight: 700;
    padding: 10px;
  }

  .dataOwnerUserIcons {
    cursor: pointer;

    &.false {
      opacity: 0.5;
    }
  }

  .not-monitored {
    color: #FF2251
  }

  .site-map-header {
    font-size: 1.5em;
    font-weight: bold;
    color: #333;
    font-family: Nunito-Regular;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    padding-bottom: 100px;
  }

  .MuiTypography-root.MuiTypography-body1 {
    width: 100%;
  }

  .selectiveUsers {
    gap: 15px;
    justify-content: space-between;
  }

  .legend-img {
    top: 7px;
    position: relative;
  }

  .dataOwnerSensitive:last-child {
    display: none;
  }

  .css-qvtrhg-MuiDataGrid-virtualScroller {
    overflow-x: hidden;
  }

  .mrg-5 {
    margin-left: 5px;
    margin-right: 15px;
  }

  .site-map-table-row {
    background-color: #F1E9F6;
  }

  .dataOwnerSummaryHeader {
    margin-top: 0px;
    font-size: 1.5rem;
    margin-left: 10px;
  }

  #group-by-select {
    font-size: 1.5rem;
  }

  .header-total-host {
    font-size: 1.5rem;
  }

  .inspect-title-right.dataOwner {
    margin-top: 0px;
  }

  .inspect-title-header.dataOwner {
    margin-bottom: 5px;
  }

  .inspect-file-fieldName.dataOwner {
    width: 100%;
  }

  .table-utility {
    margin: 5px;
    font-size: 1.5rem;
    margin-top: 35px;
  }

  .squareBadge.other.tag {
    background-color: #8000BD;
  }

  .normal-color {
    color: black;
  }
}

.btn-container {
  float: right;
  margin: 10px;
}

.header-legend .item {
  margin-left: 10px;
}

.netspace_drawer_main {
  width: 800px;
  padding: 20px 20px;
  box-sizing: border-box;

  .netspace_drawer_container {
    .netspace_drawer_header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .netspace_details {
        font-size: 20px;
        font-weight: bold;
      }
    }

    .close_drawer {
      margin-top: 1rem
    }

    .netspace_part2 {
      margin-top: 2rem;
      margin-bottom: 15px;

      .accessDetails_img {
        box-sizing: border-box;
        height: 64px;
        width: 64px;
        margin-right: 1rem;
      }

      .AccessDetails_Name {
        color: #292929;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .netspace_content_DB {
        margin: 20px 5px;
        height: max-content;
        color: #292929;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
        display: flex;
        flex-direction: row;

        .item {
          display: flex;
          align-items: center;
        }

        .inspect-file-header .dataOwner {
          display: block;
        }

        .header {
          font-weight: bold;
          color: #292929;
          font-size: 16px;
          letter-spacing: 0;
          line-height: 22px;
          margin: 10px;
        }
      }

      .AccessDetails_content {
        height: max-content;
        color: #292929;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
      }

      .serviceDataAccessDetails_content {
        color: #292929;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        text-indent: -1.5rem;
        padding-left: 1.5rem;
      }

      .AccessDetails_Roles {
        word-break: break-all;
        min-height: 22px;
        max-height: 100%;
        color: #292929;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 22px;

        .wordBreak {
          width: 100%;
          word-wrap: break-word;
          white-space: initial;
        }
      }

      .serviceDataAccessDetails_Roles {
        word-break: break-all;
        color: #292929;
        font-size: 15px;
        letter-spacing: 0;
        line-height: 22px;
        text-indent: -1.5rem;
        padding-left: 1.5rem;

        .wordBreak {
          width: 100%;
          word-wrap: break-word;
          white-space: initial;
        }
      }
    }
  }
}

.add-tag {
  cursor: pointer;
  font-size: 15px;
  color: #081981;
  top: 3px;
  position: relative;
  margin-left: 5px;
}

.new-tag-container.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12 {
  justify-content: space-between;
  display: flex;
  margin-bottom: 10px;
}

.tag-txt {
  width: '90%';
  padding: '5px';
}

.add-tag-circle {
  cursor: pointer;
  margin-top: 10px;
  color: #081981;
}

.tag-btn-container {
  width: 90%;
  padding: 5px;
  float: right;
}

.squareBadge.other.tag {
  background-color: #8000BD;
}

.circle-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  display: inline-block;

  &.HIGH {
    background-color: #E22D1B;
  }

  &.MEDIUM {
    background-color: #FF8429;
  }

  &.LOW {
    background-color: #081981;
  }
}

.host-name-container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 80%;
}

.host-icon {
  flex-shrink: 0;
}

.host-name {
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.host-add-tag {
  flex-shrink: 0;
}

.add-tag-popup {
  min-height: 50vh;
  min-width: 50vw;

  .chip-container {
    height: 30vh;
  }
}

.mrg-left-auto {
  margin-left: auto;
}

.netspace-card-pie {
  margin-top: "-25px"
}
.color-green {
  color: green;
}
.color-blue{
  color: blue;
}
.color-red{
  color: red;
}
.txt-only {
  margin-left: 10%;
}