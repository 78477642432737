.incidentState{
    height: 65px;
    width: 50px;
    color: #3E4954;
    font-family: Nunito-SemiBold;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
}

.incidentNumber{
    height: 65px;
    width: 39px;
    color: #2947FD;
    font-family: Nunito-SemiBold;
    font-size: 32px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;
}
.incidentPercentage{
    height: 65px;
    max-width: 5rem;
    font-family: Nunito-SemiBold;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 43px;

    &.green{
        color:"green"
    }
    &.red{
    color:#FF2251
    }
}
.incidentByState-Wrapper{
    box-sizing: border-box;
    height: 364px;
    width: 367px;
    font-family: Nunito-Regular;
    border-bottom: 1px solid #E5E5E5;
    border-radius: 8px;
    padding-top: 2rem;
    background-color: #FFFFFF;
}

.PriorityFilter_Containter{
  
    .critical{
        height: 20px;
        width: 65px;
        background-color: #ff2251;
        color:white;
        font-family: Nunito-SemiBold;
        font-size: 12px;
        font-weight: bold;
        border-radius: 2px;
        letter-spacing: 1px;
        line-height: 10px;
        text-align: center;
        padding-top:10px;
    }
    .high{
        height: 20px;
        width: 46.67px;
        background-color: #FAB7C5;
        color: black;
        font-family: Nunito-SemiBold;
        font-size: 12px;
        font-weight: bold;
        border-radius: 2px;
        letter-spacing: 1px;
        line-height: 10px;
        text-align: center;
        padding-top:10px;
    }
    .medium{
        height: 20px;
        width: 60px;
        background-color: #FED8B1;
        color: #FF8421;
        font-family: Nunito-SemiBold;
        border-radius: 2px;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 10px;
        text-align: center;
        padding-top:10px;
    }
    .low{
  
            height: 20px;
            width: 50px;
            background-color:#A8D5FF;
            color: #081981;
            font-family: Nunito-SemiBold;
            border-radius: 2px;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 10px;
            text-align: center;
            padding-top:10px;
        
    }

  
    
}

