.popover-container {
  display: "flex";
  flex-direction: "column";
  padding: 20px;
  height: 300px;
  width: 300px;
  .InputSelectField {
    box-sizing: border-box;
    height: 2rem;
    width: 192px;
    border-radius: 4px;
    background-color: #ffffff;
    content: none;
    outline: none !important;
    padding-left: 10px;
    box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
    &.impact {
      width: 100%;
        .MuiInputBase-input {
          width: 278px;
          padding: 10px 12px 9px 7px;
        }
    }
  }
  .InputSelectField:before {
    display: none;
  }
  .InputSelectField:after {
    display: none;
  }
  .header-section {
    border-bottom: 1px solid white;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    .header-close-icon {
      float: right;
      cursor: pointer;
      width: 16px;
      height: 16px;
      padding: 6px;
    }
    .header-title {
      display: inline-block;
      width: 260px;
      word-break: break-all;
    }
  }
  .select-container {
    padding: 7px 0px;
    padding-bottom: 15px;
    border-bottom: 1px solid #cccccc;
    .select-icon {
      font-weight: bold;
      padding: 6px 0px;
      font-size: 12px;
    }
  }

  .header-container {
    padding: 7px 0px;
    .header-title {
      font-weight: bold;
      font-size: 12px;
    }
    .header-sub-title {
      font-size: 12px;
      .vertical-bar {
        padding: 0px 3px;
      }
    }
  }
  .header-container:first-child {
    padding-top: 12px;
    padding-bottom: 7px;
  }
}
.severity-high,
.severity-medium,
.severity-low {
  font-size: 12px;
  display: inline-block;
  margin-left: 13px;
  padding: 3px;
}
.severity-high {
  color: #ff2251;
  font-weight: bold;
  background-color: rgb(255, 197, 209);
}
.severity-medium {
  color: #ff8421;
  font-weight: bold;
  background-color: rgb(255, 215, 182);
}
.severity-low {
  color: #081981;
  font-weight: bold;
  background-color: rgb(190, 195, 221);
}
.placeholder-option {
  font-size: 12px;
}

.datamap-content{
  .dataMapContentGrid {
      height: 382px;
      overflow-y: auto;
      margin-top: 10px;
    }
    .MuiBox-root-126{
        padding: 0px;
    }
    .togglerandSearch_Conatiner .header_toggler{
        margin-left: 0px;
        margin-bottom: 10px; 
        width: 32rem;
        .header_toggler_inactive{
            width: 16rem;
        }
        .header_toggler_active{
            width: 16rem;
        }
    }
}

.inspect-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

