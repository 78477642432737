.forgotpasswordContainer{
  width:100% ;
  border-radius: 50px;
  font-family: "Nunito-Regular";

}

.forgotPasswordcontainer_left {
    width:100%;
    height: 40rem;
    background-image: url("../../../assets/vizr_images/backgroundForgotPassword.svg");
    background-repeat: no-repeat;
    background-position: -1.2rem -0.5rem;
    border-radius: 30px;
  }