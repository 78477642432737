$default-color: #73b8f2;
$tooltip-color: black;
$tooltip-font-size: 12px;

.dataSelectionContainer {
  display: flex;
  width: 100%;
  justify-content: center;
}

.worldMapContainer {
  width: 100%;
  max-height: 35rem;

  .country-geography {
    fill: $default-color; // Default color
    outline: none;
    cursor: pointer;

    &:hover {
      fill: $default-color; // No color change on hover
    }

    &:active {
      fill: $default-color; // No color change on selection (pressed state)
    }
  }

  .country-tooltip {
    fill: $tooltip-color;
    font-size: $tooltip-font-size;
    font-weight: bold;
    pointer-events: none;
  }
}

.mapParentContainer {
  background-color: #d5e8eb;
  border-radius: 15px;

  .leaflet-popup-content-wrapper {
    width: 400px;
    max-width: 400px;
  }

  .leaflet-popup-content {
    width: 365px !important;
    margin: 13px 1px 13px 20px;
  }

  .leaflet-container {
    background: none;
  }

  .leaflet-pane {
    z-index: 0;
  }

  .leaflet-container a.leaflet-popup-close-button {
    font-size: 20px;
    line-height: 20px;
    width: 24px;
    height: 24px;
    top: 15px;
    right: 10px;
    font: 25px/24px Tahoma, Verdana, sans-serif;
  }

  .leaflet-touch .leaflet-control-attribution {
    display: none;
  }
}

.mapContainer {
  height: 85vh;
  width: 100%;
  border-radius: 10px;
}

.border-top {
  border-top: 1px solid #ccc;
}

.contentHeaderContainer {
  display: flex;
  flex-direction: row;
  padding-bottom: 5px;
  align-items: center;
  gap: 15px;

  &.users {
    height: 100px;
    gap: 23px;
  }

  &.inspect {
    height: 100px;
    gap: 0px;
  }
}

.contentHeaders {
  width: 140px;
  max-width: 140px;
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.dataSource {
    width: auto;
    gap: 0px;
  }

  &.inspect {
    gap: 0px;
  }
}

.locationMapIconData {
  display: flex;
  gap: 26px;

  &.inspect {
    padding-right: 10px;
  }
}

.icon-container {
  position: relative;
  display: inline-block;
}

.badge {
  position: absolute;
  top: -5px;
  right: -20px;
  background-color: red;
  color: white;
  border-radius: 60%;
  padding: 2px 2px;
  font-size: 0.75rem;
  min-width: 18px;
  text-align: center;
}

.ImageScale {
  width: 150%;
  display: flex;
  align-items: center;
  justify-content: center;

  &.NTFS {
    width: 50%;
  }
}

.ImageScalePixels {
  width: 35px;

  &.NTFS {
    width: 20px;
  }
}

.locationHeader {
  font-size: 16px;
  font-weight: 700;
}

.iconNameContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.sourceNameContainer {
  white-space: nowrap;
  max-width: 170px;
  min-width: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.locationMapIconDataBadge {
  display: flex;
  align-items: center;
  gap: 10px;
  /* Adjust gap for better spacing */
}

.locationHeaderWithBadge {
  position: relative;
  /* Make it the relative parent for absolute positioning */
  display: flex;
  align-items: center;
}

.locationHeaderContainer {
  white-space: nowrap;
  font-size: 16px;
  font-weight: 700;
}

.titleBadge {
  position: absolute;
  top: -5px;
  /* Adjust to place the badge relative to the title */
  right: -40px;
  /* Moves badge closer to the title */
  height: 20px;
  min-width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 6px;
  font-size: 0.75rem;
  line-height: 1;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  color: #fff;
  background-color: red;
  border-radius: 10px;
  /* Make it a circular badge */
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1);
}

.locationLoader {
  position: absolute;
  margin-left: 50%;
  top: 50%;
  z-index: 10;
}

.locationInspect {
  text-decoration: "underline";
  cursor: pointer;
  color: #081981;
  font-size: 18px,
}

.locationBadge {
  position: absolute;
  top: -5px;
  right: -20px;
  background-color: red;
  color: white;
  border-radius: 60%;
  display: flex;
  padding: 2px 2px;
  font-size: 0.75rem;
  min-width: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 20px;
}

.location-inspect-title-header {
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #ccc;
  padding-right: 15px;
  padding-left: 15px;
}

.ContentGrid {
  padding: 25px;
}
.dateTimeLocationContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%
}
.DAccessLogsImg{
  cursor: pointer;
}
