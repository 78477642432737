.toggle-container {
    width: 310px;
    margin-right: 15px;
  }
  .action-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 10px 25px;
  }
  .popover-custom {
    width: 250px;
  }
  .main-heatmap-content {
    height: 190px;
    display: flex;
    justify-content: space-evenly;
  }
  .summary-container{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 40%;
    line-height: 2;
    .count-distro-container{
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      .count-item {
        text-align: center;
        .count-text{
          font-size: 20px;
          font-weight: 700;
        }
        &.high-text{
          color: #FF2251;
        }
        &.medium-text {
          color: #FF8421;
        }
        &.low-text {
          color: #081981;
        }
        &.nclassified-text {
          color: #5D0A90;
        }
      }
    }
  }
  .sensitivity-heatMap-header{
    font-size: 20px;
    font-weight: bold;
    width: 35%;
  }
  .bar-container {
    flex: 1;
    display: flex;
    flex-flow: column;
    border: 2px solid #081981;
    border-radius: 3px;
    margin: 1px;
    &.dataOwner{
      max-width: 230px;
      border-radius: 10px;
    }
    &.popover {
      padding: 10px;
      border: none;
    }
    .fileCountContainer{
      width: 70%;
      display: flex;
      justify-content: space-between;
    }
    .indicator-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
      &.dataOwner{
        justify-content: unset;
        margin-bottom: 10px;
        height: 10px;
      }
      .bar-background {
        width: 70%;
        margin-right: 10px;
        background-color: #F1F1F1;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        .bar{
          height: 95%;
          border-radius: 4px;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
          &.dataOwner{
            border-radius: 10px;
          }
          &.high {
            border: 1px #FF2251 solid;
          }
          &.medium {
            border: 1px #FF8421 solid;
          }
          &.low {
            border: 1px #081981 solid;
          }
          &.nclassified {
            border: 1px #5D0A90 solid;
          }
          &.no-border{
            border: none;
          }
        }
      }
    }
    &.no-border{
      border: none;
    }
    .day-header{
      text-align: center;
    }
    .barWrap {
      width: 99%;
      background-color: #f6f6f6;
      &.barWrap-month{
        width: 95%;
      }
      &.barWrap-day{
        width: 99.85%;
      }
      &.high {
        border: 1px #FF2251 solid;
        border-radius: 1px 2px 2px 0px;
      }
      &.medium {
        border: 1px #FF8421 solid;
        border-radius: 0px 2px 2px 0px;
      }
      &.low {
        border: 1px #081981 solid;
        border-radius: 0px 2px 2px 0px;
      }
      &.nclassified {
        border: 1px #5D0A90 solid;
        border-radius: 0px 2px 2px 1px;
      }
    }
    .bar {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 18px;
      &.high {
        background: #FFC2CF;
        border-right: 1px #FF2251 solid;
        border-radius: 0px 2px 2px 0px;
      }
      &.medium {
        background: #FFC596;
        border-right: 1px #FF8421 solid;
        border-radius: 0px 2px 2px 0px;
      }
      &.low {
        background: #DEEEFD;
        border-right: 1px #081981 solid;
        border-radius: 0px 2px 2px 0px;
      }
      &.nclassified {
        background: #EAC8FF;
        border-right: 1px #5D0A90 solid;
        border-radius: 0px 2px 2px 0px;
      }
      &.no-border{
        border-right: none;
      }
    }
  }
  .legend-item {
    width: max-content;
    padding: 0px 12px;
    font-size: 12px;
    font-weight: 600;
    border-radius: 2px;
    text-transform: uppercase;
    &.high {
      background: #F5BFCA;
    }
    &.medium {
      background: #FBE6D5;
    }
    &.low {
      background: #DEEEFD;
    }
    &.nclassified {
      background: #EAC8FF;
    }
  }
  .heatmap-container {
    width: 100%;
    padding: 0px 10px;
    display: flex;
    flex-direction: column;
    .heatmap-groupdata{
      overflow-y: auto;
    }
    .group-main-container{
      display: flex;
      .header-container{
        flex-basis: 15%;
        display: flex;
        align-items: center;
        word-break: break-word;
      }
    }
    .group-container {
      display: flex;
      &.header-container{
        margin-left: 13%;
        margin-right: 17px;
      }
      &.full-width{
        flex-basis: 100%;
      }
    }
  }
  .month-header{
    margin-left: 13%;
    font-size: 14px;
    font-weight: bold;
    padding: 5px;
  }
  .month-header-M{
    color: #2947FD;
    margin-right: 10px;
  }
  .no-data{
    margin-left: 10px;
  }