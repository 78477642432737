.ntfsS3fieldContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .MuiFormControl-fullWidth{
    width: 84%;
    background: white;
  }
}

.ntfsMainContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 5%;
}

.platformNTFSFieldParentContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 24rem;
  .MuiOutlinedInput-input {
    padding: 7.5px 14px;
  }
}
.platformFieldHeadingsContainer {
  display: flex;
  align-items: center;
}
.platformFieldHeadings {
  color: #292929;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 0;
  margin-right: 0.5%;
}

.errorMessageContainer {
  color: red;
  margin-top: 20px;
  font-size: 12px;
}