/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiTypography-h5 {
  font-size: 1rem;
}

body{
  font-family:  'Nunito', sans-serif;
  position: unset !important;
} */

body {
  margin: 0;
  font-family: Nunito-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.MuiTypography-h5 {
  font-size: 1rem;
}

[class^="MuiTypography"] {
  font-family: Nunito-Regular !important;
}

.MuiInputBase-input,
.MuiAutocomplete-paper {
  font-family: Nunito-Regular !important;
}

.enhanced-table-root .MuiTypography-root.title {
  /* font-family: SFNSDisplayRegular !important; */
  font-size: 18px;
}

input[type="text"]:disabled {
  background: #dddddd;
  cursor: not-allowed;
}

.header_toggler{
  height: 40px;
  border-radius: 16px;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  display: flex;
  margin-left: 13rem;
  box-shadow: inset 0 1px 2px 0 rgb(0 0 0 / 22%);
  background-color: rgba(249, 249, 249, 1);
}

.header_toggler_active{
  height: 20px;
  border-radius: 22px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 8px -3px rgba(1,18,121,0.5);
  color:#2947fd;
  justify-content: center;
  align-items: center;
  padding: 10px 50px;
  margin-top: 2px;
  font-size: 12px;
}

.header_toggler_inactive{
  height: 20px;
  color: #706F7E;
  font-size: 12px;
  letter-spacing: 0;
  justify-content: center;
  align-items: center;
  padding: 10px 50px;
}
.toolTip {
  float: left;
  margin: 5px;
}
.labelStyle {
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#scatter {
  padding: 15px;
  margin-left: 15px;
}

#align1 {
  position: absolute;
  top: 85px;
  left: 26px;
}

#align2 {
  position: absolute;
    top: 85px;
    left: 150px;
}
.current-value {
  display: none;
}
.anomalies {
  border-left: 1px solid #dbd9d9;
  float: left;
}