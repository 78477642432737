.ProfileEditing {
  .profileHeading {
    height: 22px;
    width: 150px;
    color: #292929;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 2rem;
  }

  .ProfileEditing_content {
    margin-top: 3%;
    min-height: 780px;
    height: 100%;
    width: 82%;
    margin-left: 13rem;
    margin-right: 1rem;
    padding-left: 2%;
    padding-top: 2%;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  }
  .ProfileEditing_Input {
    box-sizing: border-box;
    height: 32px;
    width: 90%;
    border: 1px solid #dfe1e9;
    border-radius: 4px;
    background-color: #ffffff;
    margin-bottom: 2rem;
    padding-left: 10px;
  }
  .ProfileEditing_Input.disabled {
    background-color: #dfe1e9 !important;
  }

  .ProfileEditing_Input.ProfileEditing_Input_phoneNumber {
    width: 90% !important;
  }

  .ProfileEditing_Roles{
    width: 90%;
    word-break: initial;
  }

  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .managementFooter.profileEditing_footer {
    height: 110px;
    width: 34% !important;
    margin-left: 13rem;
    background-color: #ffffff;
    box-shadow: 0 -2px 2px 0 rgba(0, 0, 0, 0.3);
  }
  .userEditing_ImageContainer.userEditing_ImageContainer_profileEditing {
    width: 13rem !important;
    margin-left: -0.8rem;
    margin-bottom: 2rem;
  }
  .profileSettings {
    height: 22px;
    width: 150px;
    color: #292929;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
  }
  .profileHeading.resetPassword {
    margin-bottom: 2.5rem;
    margin-top: 2rem;
  }
  .toggleFlex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    margin-top: 0.8rem;
  }
}
