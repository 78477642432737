.IncidentFilter{
       min-height: 200px;
        width:200px;
        max-width: 33%;
        display: flex;
        flex-direction: column;
        padding-left: 0.5rem;
        overflow: auto;
        overflow-x:hidden;
        border-right: 1px solid gray;

        &.extraWidth{
            width:100%;
        }
      
}

.AuthenticationFilter{
    
    min-height: 200px;
    width:200px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding-left: 1.5rem;
    overflow: auto;
    // overflow-x:hidden;
    // border-right: 1px solid gray;

    &.extraWidth{
        width:100%;
    }
  
}

.endpointsFilter{
    
    min-height: 200px;
    min-width:250px;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding-left: 1.5rem;
    overflow: auto;
    overflow-x:hidden;
    border-right: 1px solid gray;


}
.patchmonitoringFilter{
    
    min-height: 200px;
    min-width:250px;
    max-width: 25%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding-left: 1.5rem;
    overflow: auto;
    overflow-x:hidden;
    border-right: 1px solid gray;
}
.incidentFilter_Name{
    max-width:20rem
}

.PriorityFilter_Containter {
    margin-bottom: 1rem;
  }
  
  .effectiveStatus_filter {
    margin-top: 17px
  }
  .IncidentStateFilter {
    min-height: 200px;
    width: 270px;
    max-width: 33%;
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;
    overflow: auto;
    overflow-x: hidden;
    border-right: 1px solid gray;
  
    &.extraWidth {
      width: 100%;
    }
  }
  
  .filterStyle {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }
  
  .mdrIncidents div:last-child {
    border-right: none;
  } 

  .PriorityFilter {
    height: 150px;
    overflow: auto;
  }