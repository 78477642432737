@mixin filter-color($level) {
    @if $level == high {
        color: $reddish-pink;
        background-color: rgb(255,197,209);
    }
    @else if $level == med {
        color: $tangerine;
        background-color: rgb(255,215,182);
    }
    @else if $level == low {
        color: $royal;
        background-color: rgb(190,195,221);
    }
    @else if $level == not_processed {
        color: #5D0A90;
        background-color: rgb(255, 196, 255);
    } 
    @else if $level == ok {
        color: $black;
        background-color: $weird-green;
    }
    @else if $level == draft {
        color: $royal;
        background-color: $baby-blue;
    }
    @else if $level == 'inactive' {
        color: $purplish-grey;
        background-color: $tinted-gray;
    }
    @else if $level == 'disabled' {
        color: $white;
        background-color: $purplish-grey;
    }
    @else if $level == alertCount {
        color: $royal;
        background-color: rgb(190,195,221);
    }
}