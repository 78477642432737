.wrapper{
  position: relative;
  top: 0;
  height: 100vh;

  &.wrapper-full-page{
    min-height: 100vh;
    height: auto;
  }
  .MuiBadge-root{
    position: unset;
}

}

.sidebar{
background-image: url("../../../Images/inspect/03.\ Background.png");
height: 1000px;
//width: 159px;
position: absolute;
top: 0;
left: 0;
z-index: 4;
background-size: cover;
background-repeat: no-repeat;
background-position: center center;
display: block;
margin-top: 24px;
margin-left: 24px;
border-radius: 32px;

&.MDRsidebar_bgimg{
  background-image: url("../../../Images/inspect/leftpanelMDR.svg");
  // background-color:#8000BD;
  color:#8000BD
}
&.Governorsidebar_bgimg{
  background-image: url("../../../Images/inspect/leftpanelGovernor.svg");
  color: black;

}
&.sidebar_bgimg{
  background-image: url("../../../Images/inspect/03.\ Background.png");
}


.sidebar-wrapper{
  width: 100%;
  padding: 6px;
  min-height: 100%;
  box-sizing: border-box;
  max-height: calc(100vh - 705px);
  z-index: 4;
  position: relative;
 
  a {
    text-decoration: none !important;
  }
  li{
    padding-bottom: 6px;
    height: 55px !important;
    position: relative;
  }
  a.nav-link{
    margin-top:5px;
    padding: 0;
    .side-bar-img{
      color: white;
      opacity: 1;
      margin-left: 6px;
      position: absolute;
      top: 20%;
    }
    .side-bar-para{
      font-size: 14px;
      color: white;
      line-height: 18px;
      font-family: $font-family-base; 
      margin: 0 0 0 40px;
      width: 110px;
      &.active{
        font-weight: bold;
        font-size: 16px;
      }
      &.user-title{
        margin-left: 15px;
      }
      &.mdr-para{
        margin-left: 10px;
      }
    }
  }
  .alert-badge{
    .MuiBadge-anchorOriginTopLeftRectangle{
      left: 20%;
  }
  }
  .error{
    .MuiBadge-anchorOriginTopLeftRectangle{
      left: 20%;
      background-color: #FF8421;
      color: white;
    }
  }
  .MDR-list-items{
    margin-top: 15px;
    margin-left: 6px;
  }
  .MDR-main-logo{
    color: white;
    margin-top: 2rem;
    margin-bottom: 30px;
    text-align: center;
  }
}

}
.sidebar-small-icon{
  padding: 2px;
}
.dashboard-style{
  padding: 2px;
}
.profile-pic{
  margin-left: 5px;
}

.shrunk-sidebar{
//background-image: url("../../../vizr_images/03.\ Background-1.png");
background-color: #2947FD;
height: 900px;
width: 80px;
position: absolute;
top: 0;
left: 0;
z-index: 4;
background-size: cover;
background-position: center center;
display: block;
box-shadow: 20px 15px 150px 10px rgba(0,0,0,0.6);
margin-top: 35px;
margin-left: 20px;
border-radius: 15px;

&.MDR_bgimg_shrunk{
  background-color: #8014BD;
  width: 68px;
  height: 1000px;
 }
&.bgimg_shrunk{
  background-image: url("../../../vizr_images/03.\ Background-1.png");
}

.MuiBadge-root {
  position: relative;
}

.sidebar-wrapper{
  width: 100%;
  min-height: 100%;
  max-height: calc(100vh - 705px);
  z-index: 4;
  position: relative;
  a {
    text-decoration: none !important;
  }
  a.nav-link{
    padding-top: 25px;
  }
  .side-bar-img{
      color: white;
      opacity: 1;
      margin-left: 25px;
  }
  div.nav-link{
    padding-top: 20px;
  }
  .dropdown-icon{
    padding: 5px;
  }
  .forward{
      position: absolute;
      bottom:1%;
      left: 15%;
      &.mdr-forward{
        left: 11%;
      }
  }
  .email-support-text{
    color: #081981;
    font-size: 15px;
    font-weight: 500;
  }
  .active-pro {
    img{
      position: absolute;
      bottom: 7%;
      left: 31%;
      box-sizing: border-box;
    }
  }
  .shrink-user-account{
    position: absolute;
    bottom: 11%;
    left: 26%;
    .userName{
      color: #535353 !important;
      border: 1px solid #696969 !important;
      width: 11px;
      height: 11px;
      border-radius: 50%;
      padding: 6px;
      margin-left: 4px;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      background-color: #ACACAC;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
  .shrunk-druvstar-main-logo{
    padding: 0;
    margin-left: 15px;
  }
  .shrunk-druvstar-image{
    height: 30px;
    width: 50px;
    margin-left: 15%;
  }
  .alert-badge{
    .MuiBadge-anchorOriginTopLeftRectangle{
      left: 105%;
      top: -45%;
    }
  }
  .error{
    .MuiBadge-anchorOriginTopLeftRectangle{
      left: 105%;
      top: -45%;
      background-color: #FF8421;
      color: white;
    }
  }
  .MDR-shrunk-sidebar-icons{
    justify-content: center;
  }
  .MDR-shrunk-sidebar-logo{
    width: 50px;
  }
}
}

@media all and (min-width: 320px) {
.sidebar .nav>li.active-pro {
  position: absolute;
  bottom: 8%;
  left:7%;
}
.sidebar .nav>li.user-account {
  position: absolute;
  bottom: 11%;
  cursor: pointer;
}
.user-account {
 .userName{
  color: #535353 !important;
  border: 1px solid #696969 !important;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  padding: 6px;
  margin-left: 4px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  background-color: #ACACAC;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
 }
}
.sidebar .nav>li.forward {
  position: absolute;
  bottom: 4%;
  left: 33%;
}
.sidebar .nav>li.druvstar-logo {
  padding-left: 19px;
  padding-bottom: 20px;
  margin-top: 20px;
  height: 78px !important;
}
}


.main-panel {
  position: relative;
  float: right;
  width: 100%;
  background: linear-gradient($white, $white-two);
  margin-top: 77px;

  > .content {
      padding: 78px 30px 30px 280px;
      min-height: calc(100vh - 70px);
    }
&.shrunk{
  width: 105% !important;
}
h2{
  font-weight: 600;
}
}

@media only screen and (max-width: 1281px) {
  .sidebar{
    position: absolute;
    .sidebar-wrapper{
      li{
        height: 35px !important;
      }
    }
  }
}
@media only screen and (min-width: 1282px){
.sidebar{
  height: 1065px !important;
  // background-size: auto;
  position: absolute;
  z-index: 4;
  .sidebar-wrapper{
    li{
      height: 35px !important;
    }
  }
}
}