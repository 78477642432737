.main {
    // height: 815px;
    // overflow-y: scroll;

    .description {
        height: 105px;
        font-family: $font-family-base;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 24px;
        padding-left: 210px;
        background: linear-gradient($pale-grey-0, $pale-grey);
    }
    .all-data-sources-title{
        display: grid;
        width: 85%;
    }
}

.filter-table-container {
    display: flex;
    margin: 24px 24px 12px 211px;
    font-family: $font-family-base;
    &.riskPilot{
        justify-content: center;
        margin-top: 10%;
        margin-bottom: 25%;
    }
    &.riskPilotForm{
        flex-direction: column;
    }
    &.riskPilotTable {
        justify-content: center;
        margin-bottom: 4%;
    }
}

.alerts-table-container {
    margin: 24px 24px 12px 211px;
    font-family: $font-family-base;
}

.table-container {
    font-family: $font-family-base;
    width: 100%;
    border-radius: 4px;
    // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    background-color: $white;
}

.filter-header {
    color: '#292929';
    font-size: '18px';
    padding-right: '10px';
    padding-top: '10px';
    font-family: $font-family-base;
}

.chart-container {
    margin: 24px 24px 12px 211px;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    background-color: $white;
    &.alert-policies{
        .MuiIconButton-root {
            display: none;
        }
    }
    &.alerts{
        .MuiIconButton-root {
            display: none;
        }
    }
}

// .MuiSnackbarContent-root{
//     background-color: $success !important;
//     border-radius: 6px !important;
// }
// .error{
//     background-color: $danger !important;
//     border-radius: 6px !important;
// }

.width-100 {
    width: 100%;
}

.dataMap-dialog-section .dataMap-dialog-view {
    border: 1px solid #ddd;
    border-top: none;
    border-radius: 4px;
}

.children-trayview-panel {
    width: 100%;

    .contents-panel {

        header.MuiAppBar-colorDefault {
            background: #f9f9f9;
            box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.22);
            border-radius: 22px;
            max-height: 32px;
            width: 703px;
            margin: auto;
        }

        header.MuiAppBar-colorDefault button {
            height: 32px;
            max-height: 32px;
            min-height: 32px;
        }

        header.MuiAppBar-colorDefault button.Mui-selected {
            border-radius: 22px !important;
            background-color: #FFFFFF;
            box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
        }

        header.MuiAppBar-colorDefault button:first-child {
            border-radius: 16px 16px 16px 16px;
        }

        header.MuiAppBar-colorDefault button:last-child {
            border-radius: 0px 16px 16px 0px;
        }

        header.MuiAppBar-colorDefault .MuiTabs-indicator {
            background: transparent !important;
        }
    }
}