.createAgent_Containers{
    font-family: 'Nunito' !important;
    height: 100%;

    .createAgent_Content{
        margin-top: 3%;
        min-height: 805px;
        height:100%;
        width: 83%;
        margin-left: 15%;
        padding-left: 2%;
        padding-top: 2%;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.3);

       .createAgent_Content_heading{
        height: 22px;
        width: 116px;
        color: #292929;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 22px;
        margin-top: 5px;
        margin-bottom: 10px;
       }

       .createAgent_Content_Container{
           display: flex;
           flex-direction: row;
           justify-content: space-between;

           .createAgent_input{
            box-sizing: border-box;
            height: 32px;
            width: 224px;
            border: 1px solid #DFE1E9;
            border-radius: 4px;
            background-color: #FFFFFF;
            outline: none;
            margin-top: 6px;
            margin-bottom: 35px;
           }
           .createAgent_input:hover{
               outline: none;
               border: 1px solid gray
           }
           .createAgent_input:active{
            outline: none;
            border: 1px solid gray
        }
      
       }
    }

    .rightFlexContainer{
        display: flex;
        flex-direction: column;

        .inputFileContainer{
           box-sizing: border-box;
           height: 121px;
           width: 224px;
           border: 1px dashed rgba(11,11,244,0.23);
           border-radius: 2px;
           background-color: rgba(152,196,255,0.12);
           position: relative;
           cursor: pointer;
           z-index: 10;
           

           .inputFile{
            height: 121px;
            width: 224px;
            cursor: pointer;
            opacity: 0;
            position: relative;
            z-index: 10;
           }
           .inputFileText{
            position: absolute;
            top:30%;
            z-index: -10;
            cursor: pointer;
           }
        }
    }
    .createAgentButton{
        width:20%;
        margin-top: 15px;
        margin-bottom: 35px;
    }
   .onSelected{
    height: 53px;
    width: 285px;
    color:white;
    background-color: #706F7E !important;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.2);
   }
   .onHover{
       color:black  !important;
   }
   .PlatformList{
       margin-top: 5%;
   }
   .createAgent_RichtextEditor{
       min-height: 350px;
       margin-bottom: 10%;
       width:100%;
       max-height:350px;
       overflow-y: scroll;
       overflow-x: hidden;
}
    .CreateAgentFooter{
        width:83% !important;
        margin-left: 15%  !important;
    }
   
}