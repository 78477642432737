.enhanced-table-root{
  width: 100%;

}
.help-icon {
  height: 20px;
  margin-top: 12px;

  &.riskPilot {
    margin-top: 0px;
  }
  &.NTFS {
    height: 15px;
    margin-top: 7px;
    margin-bottom: 5px
  }
}
.paper{
  width: 100%;
  margin-bottom: 15px;
}
.enhanced-table {
  min-width: 750px,

}
.MuiTableCell-paddingCheckbox {
  position: sticky;
}
.enhanced-table.AccessDetailsPopUpTable
{
min-width: 100%,
}
.enhanced-table-container { 
  max-height: 450px;
  overflow-x: auto;
  &.data-sources{
    max-height: 800px !important;
  }
  &.accessAnalomy{
    overflow-x: hidden;
    // width:650px !important;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.3);

 }
 &.All_Access{  

  overflow-x: hidden;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.3);
 }
 &.one_drive_access_statements{
  max-height: 202px !important;;
 }



}
.enhanced-table-head {
  position : sticky;
  top: 0;
  z-index: 1;
  background-color: $light-royal-blue;
      &.MuiTableHead-root {
          &.th:first-child {
              border-radius: 6px 0 0 0
  }
  &.th:last-child {
    border-radius: 6px 6px 0 0;
  }
}
}
.resolved-table-head{
  display: table-caption !important;
  color: $white;
  height: 40px;
  padding: 10px 0px 0px 116px;
  background-color: $light-royal-blue;
      &.MuiTableHead-root {
          &.th:first-child {
              border-radius: 6px 0 0 0
  }
  &.th:last-child {
    border-radius: 6px 6px 0 0;
  }
}
}
.enhanced-table-row {
  &:hover {
    background-color: '#fbfbfb';
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.5);
  }
  &.Mui-selected {
    background-color: '#fbfbfb';
    &.hover {
      background-color: none !important
    }
  }
}
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1;
  margin: -1;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20;
  width: 1;
}
.avatar-root{
  display: block;
  .avatar{
      z-index: 0 !important;
  }
}
.clear-filter-container{
  background-color: $white;
  height: 54px;
  border-top-left-radius: 6px; 
  border-top-right-radius: 6px;
  border-bottom: 2px solid #979797;
  .clear-filter {
      display: block !important
  }
}
.alerts-table-popover {
.MuiPopover-paper{
  width: 95px !important;
  height: 135px !important;
  background-color: $white;
}
}
.popper-root{
  display: flex;
  position: relative;
  width: fit-content;
  height: 430px;
  background-color: $white;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 10px 10px;
  .typography {
      padding: 16px;
  }
  &.oneDrive-filter{
    height: 200px;
  }
  &.monitors{
    width: 276px !important;
    height: 285px !important;
    padding: 0 !important;
    overflow-x: scroll;
    fieldset{
      padding: 5px !important;
      border-left: none !important;
    }
  }
  &.severities{
    width: 153px !important;
    height: 135px !important;
  }
  &.mdrIncidents{
    width:830px;
  }
   &.mdrAuthetication{
    width:300px !important;
  }
  &.mdrendpoints{
    min-width:570px;
  }

}

.edit-policy-button{
  margin-left: 10px;
  margin-right: 10px;
  white-space: nowrap;
}

.toolbar-root{
  padding-left: 16px;
  padding-right: 8px;

  .table-buttons{
    box-shadow: 0 5px 8px -3px rgba(1,18,121,0.5);    
  }

  .min-margin{
      margin: $min-margin;
  }
  .title {
    color: $black;
    font-size: $font-size-lg;
    font-weight: 700;
    padding-right: 10px;
    padding-top: 8px;
  }
  .count {
    color: $purplish-grey;
    background-color: $pale-grey;
    margin-top: 13px;
    margin-right: 10px;
    width: max-content;
    height: 20px;
    font-family: $font-family-base;
    font-size: $font-size-sm;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.83;
    letter-spacing: 1px;
    text-align: center;
    padding-left: 3px;
    padding-right: 3px;
  }
}
.severity-span {
  cursor: pointer;
  margin-bottom: 10px;
  text-align: left;
  :hover {
    border: 2px solid $royal;
  }
}
.last-cell {
  color: #ffffff !important;
  background-color: #2947fd;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.first-cell {
  color: #ffffff !important;
  background-color: #2947fd;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.other-cell {
  color: #ffffff !important;
  background-color: #2947fd;
}

.MuiPaper-elevation8 {
  box-shadow: 0px 1px 3px 0px rgb(196, 196, 199) !important;
}

.dataMap-tray-count {
  color: $purplish-grey;
  background-color: $pale-grey;
  margin-top: 18px;
  margin-right: 10px;
  width: max-content;
  height: 20px;
  font-family: $font-family-base;
  font-size: $font-size-sm;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: 1px;
  text-align: center;
  padding-left: 3px;
  padding-right: 3px;
}
.dataMap-tray-header {
  &.MuiTableHead-root {
    &.th:first-child {
      border-radius: 6px 0 0 0;
    }
  .count {
      color: $purplish-grey;
      background-color: $pale-grey;
      margin-top: 13px;
      margin-right: 10px;
      width: max-content;
      height: 20px;
      font-family: $font-family-base;
      font-size: $font-size-sm;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83;
      letter-spacing: 1px;
      text-align: center;
      padding-left: 3px;
      padding-right: 3px;
    }
}
.severity-span{
cursor: pointer;
margin-bottom: 10px;
text-align:left;
:hover{
  border: 2px solid $royal;
}
}
.last-cell{
color: #ffffff !important;
background-color: #2947fd;
border-top-right-radius: 5px;
border-bottom-right-radius: 5px;
}
.first-cell{
color: #ffffff !important;
background-color: #2947fd;
border-top-left-radius: 5px;
border-bottom-left-radius: 5px;
}
.other-cell{
color: #ffffff !important;
background-color: #2947fd;
}



.MuiPaper-elevation8{
box-shadow: 0px 1px 3px 0px rgb(196, 196, 199) !important;
}

.dataMap-tray-count{
      color: $purplish-grey;
      background-color: $pale-grey;
      margin-top: 18px;
      margin-right: 10px;
      width: max-content;
      height: 20px;
      font-family: $font-family-base;
      font-size: $font-size-sm;
      font-weight: 800;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.83;
      letter-spacing: 1px;
      text-align: center;
      padding-left: 3px;
      padding-right: 3px;
}
.dataMap-tray-header{
&.MuiTableHead-root {
  &.th:first-child {
      border-radius: 6px 0 0 0
  }
  &.th:last-child {
  border-radius: 6px 6px 0 0;
  }
}
}}

.first-cell-mdr{
  color: #ffffff !important;
  background-color: #8014BD;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  
  .MuiTableSortLabel-root:hover{
    color:lightgray
  }
  
  .MuiTableSortLabel-root.MuiTableSortLabel-active{
    color: grey
  }
  }

  
.last-cell-mdr{
  color: #ffffff !important;
  background-color: #8014BD !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  .MuiTableSortLabel-root:hover{
    color:lightgray
  }
  
  .MuiTableSortLabel-root.MuiTableSortLabel-active{
    color: grey
  }
  }
  .CircularProgressbar {
    width:50px !important;
  }

  .other-cell-mdr{
    color: #ffffff !important;
    background-color: #8014BD;
    .MuiTableSortLabel-root:hover{
      color:lightgray
    }
    
    .MuiTableSortLabel-root.MuiTableSortLabel-active{
      color: lightgray
    }
    }
  