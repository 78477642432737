.accessDetails_container {
  display: flex;
  flex-direction: column;
  .access_Details {
    height: 24px;
    width: max-content;
    color: #292929;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 1rem;
    cursor: pointer;
  }
  .accessDetails_part2 {
    margin-top: 2rem;
    margin-bottom: 15px;
    .accessDetails_img {
      box-sizing: border-box;
      height: 64px;
      width: 64px;
      margin-right: 1rem;
    }
    .AccessDetails_Name {
      color: #292929;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 22px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .AccessDetails_content_DB {
      height: max-content;
      color: #292929;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
    }
    .AccessDetails_content {
      height: max-content;
      color: #292929;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
    }
    .serviceDataAccessDetails_content{
      color: #292929;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      text-indent: -1.5rem;
      padding-left: 1.5rem;
    }
    .AccessDetails_Roles {
      word-break: break-all;
      min-height: 22px;
      max-height: 100%;
      color: #292929;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 22px;
      .wordBreak {
        width: 100%;
        word-wrap: break-word;
        white-space: initial;
      }
    }
    .serviceDataAccessDetails_Roles{
      word-break: break-all;
      color: #292929;
      font-size: 15px;
      letter-spacing: 0;
      line-height: 22px;
      text-indent: -1.5rem;
      padding-left: 1.5rem;
      .wordBreak {
        width: 100%;
        word-wrap: break-word;
        white-space: initial;
      }
    }
  }
  .accessDetails_banner {
    height: 70px;
    border-radius: 20px 20px 0 0;
    background-color: #f9f9f9;
    box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;

    .accessDetails_bannercontent {
      height: 18px;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      padding-bottom: 2rem;
    }
    .accessDetails_bannercontent.active {
      color: #2947fd !important;
      border-bottom: 3px solid #2947fd;
    }
    .accessDetails_bannercontent.inactive {
      color: #706f7e !important;
    }
  }
}
.mdr-container{
  display: flex;
  flex-direction: column;
  .AccessDetails_content_DB {
    height: max-content;
    color: #292929;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    padding:6px 6px;
    word-break: break-all;
  }
  .access_Details {
    height: 24px;
    width: 400px;
    color: #292929;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 1rem;
    cursor: pointer;
  }
}
.data-source-container {
  display: flex;
  flex-direction: column;
  .MuiOutlinedInput-input {
    padding: 10.5px 14px;
  }
  .container {
    height: auto;
  }
  .button-group {
    bottom: 18px;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
    &.GDrive {
      margin-top: 0px;
    }
  }
  .access_Details {
    height: 24px;
    width: 300px;
    color: #292929;
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    margin-top: 1rem;
    white-space: noWrap;
    cursor: pointer;
    &.winlogbeatTitleTextContainer {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 10%;
    }
    &.logSourceTitleTextContainer {
      display: flex;
      align-items: center;
    }
  }
}
.allAccessRow {
  .LOW {
    height: 35px;
    opacity: 1;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 10px;
    color: #0021f5;
    background-color: #d2deeb;
    font-family: Nunito-Regular;
    padding: 0.2rem;
  }
  .MEDIUM {
    height: 35px;
    opacity: 1;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 10px;
    border-radius: 2px;
    color: #ff8421;
    background-color: #fed8b1 !important;
    font-family: Nunito-Regular;
    padding: 0.2rem;
  }
  .HIGH {
    height: 35px;
    opacity: 1;
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 10px;
    color: #ff2251;
    background-color: #e9b1bd !important;
    font-family: Nunito-Regular;
    padding: 0.2rem;
  }
}
.NoSensitivity {
  height: 35px;
  margin-right: 5px;
  color: black;
  opacity: 0.9;
  font-family: Nunito-Regular;
  border-radius: 2px;
}
.sensitiveAccess_Container {
  height: 100%;
  width: 80%;
  border-radius: 4px;
  background-color: #dfe1e9;
  padding-right: 10px;
}
.filePopUp{
  width: 550px;
  padding: 20px;
}
.filePopUp-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  .close-icon{
    padding: 4px;
    min-width: 24px;
    margin-right: 17px;
  }
}
.file-details-header{
  font-size: 20px;
  font-weight: bold;
  padding: 4px;
}
.file-details-row{
  display: flex;
  align-items: center;
}
.userName{
  color: #535353 !important;
  border: 1px solid #696969 !important;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  padding: 6px;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  background-color: #ACACAC;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
.file-owner{
  display: flex;
  align-items: center;
  gap: 3%;
  flex-basis: 46%;
}
.access-time-details {
  display: flex;
  gap: 3%;
  padding: 5px;
  .access-details-header{
    font-weight: bold;
  }
  .access-details-container {
    width: 70%;
    border: 1px solid #DEDEDE;
    box-shadow: 0px 0px 16px rgba(128, 128, 128, 0.15);
    .access-details-table-cell {
      border: 1px solid rgba(224, 224, 224, 1);
      padding: 4px 8px;
      font-size: 12px;
      width: 50%;
    }
  }
}

.filePopUp-fieldName{
  font-size: 14px;
  line-height: 18px;
  padding: 4px;
  letter-spacing: 0.03rem;
  padding: 5px;
  p{
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.last-scan{
    white-space: nowrap;
  }
  &.access-details-field{
    padding: 0;
  }
  &.file-details-field{
    flex-basis: 90%;
  }
  .field-header{
    font-weight: bold;
    margin-right: 10px;
  }
  .sensitivity-field{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.sensitivity-select{
  width: 100px;
}
.InputSelectField {
  box-sizing: border-box;
  height: 2rem;
  width: 192px;
  border-radius: 4px;
  background-color: #ffffff;
  content: none;
  outline: none !important;
  padding-left: 10px;
  box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
  &.file-pop-up{
    MuiInputBase-input{
      padding: 3px 6px 0px;
    }
    padding: 0%;
  }
  &.impact {
    width: 100%;
      .MuiInputBase-input {
        padding: 10px 12px 9px 7px;
      }
  }
}
.InputSelectField:before {
  display: none;
}
.InputSelectField:after {
  display: none;
}
.filePopUp-tabs {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  margin-top: 40px;
  min-height: 300px;
  overflow-y: auto;

  header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorDefault.MuiPaper-elevation4 {
    border-radius: 22px;
    background-color: #F9F9F9;
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.22);
    width: fit-content;
    margin: auto;

    .MuiTabs-root {
      max-height: 32px;
      min-height: 32px;
    }

    .MuiTab-root {
      max-height: 28px;
      min-height: 28px;
      align-self: center;
    }

    .PrivateTabIndicator-colorPrimary-18 {
      background-color: transparent
    }
  }

  .MuiTab-textColorPrimary.Mui-selected {
    border-radius: 22px;
    box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
    transform: rotate(-360deg);
    color: #2947FD;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    height: 32px;
  }
}
.sensitivity-classification{
  margin-top: 15px;
  .classification-table-container{
    border: 1px solid #DEDEDE;
    box-shadow: 0px 0px 16px rgba(128, 128, 128, 0.15);
  }
  .classification-table-cell{
    border: 1px solid rgba(224, 224, 224, 1);
    padding: 12px;
    font-size: 12px;
    font-weight: 500;
    &.type-header{
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 1px;
    }
  }
}
.severity-field {
  display: flex;
  align-items: center;
  gap: 10px;
  &.save-buttons {
    gap: 15px;
  }
}
.id-tooltip{
  cursor: pointer;
 }
.tooltip-content{
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
}
.copy-icon{
  margin-left: 5px;
  cursor: pointer;
}
.copied-snackbar{
  .copied-alert{
    color:#fff;
    background-color:#4caf50
  }
  
}
