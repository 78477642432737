.appBg{
    background-color: $royal;
  }
  .app-navbar-grow{
    flex-grow: 1;
  }
  .app-navbar-search {
    position: relative;
    border-radius: 3px;
    background-color: $white;
    color: $royal;
     &:hover {
      background-color: white;
    }
    margin-right: 16px;
    margin-left: 0px;
    width: 100%;
  }
  .app-navbar-inputRoot {
    color: $royal,
  }
  .app-navbar-inputInput {
    padding: 8px 8px 8px 0;
    // vertical padding + font size from searchIcon
    padding-left: calc(1em + 32px);
    // transition: theme.transitions.create('width'),
    width: 100%;
  }
  .app-navbar-year {
    padding-left: calc(1em + 2px);
    width: 342px !important;
  }
  .app-navbar-notification{
    color: $royal;
    background-color: $white;
    min-width: 50px;
  }
  .app-navbar-last-container{
    padding: 0px 5px 0px 5px;
    font-family: $font-family-base;
    font-size: 12px; 
    line-height: 18px;
    letter-spacing: 0;
    span.last-updated{
      opacity: 0.75;
      line-height: 18px;
    }
    span.time{
      font-size: 14px !important;
      font-weight: 600; 
      line-height: 19px;
    }
  }