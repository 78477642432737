.datamap-container-box {
  .MuiGrid-spacing-xs-3 {
    width: 100%;
    margin: 0;

    .MuiGrid-item {
      padding: 2px;
    }
  }
}

.datamap-breadcrumb {
  padding: 14px 24px 14px 210px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2);
  background: transparent;
}

.datamap-header-content {
  padding: 14px 24px 14px 196px;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2);
  background: transparent;
}

.inspect-contents {
  padding: 0 24px 0 211px;

  header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorDefault.MuiPaper-elevation4 {
    background: transparent;
    box-shadow: none;
    text-align: center;
    justify-content: flex-end;
  }

  div.contents-panel {
    background: #fff;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    margin-top: 10px;
    min-height: 300px;
    max-height: 450px;
    overflow-y: auto;

    header.MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorDefault.MuiPaper-elevation4 {
      border-radius: 22px;
      background-color: #F9F9F9;
      box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.22);
      width: auto;
      display: inline-block;

      .MuiTabs-root {
        max-height: 32px;
        min-height: 32px;
      }

      .MuiTab-root {

        max-height: 32px;
        min-height: 32px;
      }
      .Mui-selected {
        min-height: 28px;
        max-height: 28px;
        align-self: center;
      }
      .PrivateTabIndicator-colorPrimary-18 {
        background-color: transparent
      }
    }

    .MuiTab-textColorPrimary.Mui-selected {
      border-radius: 22px;
      background-color: #FFFFFF;
      box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
      transform: rotate(-360deg);
      color: #2947FD;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 18px;
      text-align: center;
      height: 32px;
    }
  }
}

.comments-section {
  text-align: left;
  border-top: 1px solid #979797;

  .comments-list-arr {
    margin-top: 45px;

    .comments-list {
      border-radius: 4px;
      background-color: #DFE1E9;
      padding: 12px;
      margin-bottom: 15px;

      .comments-text {
        color: #292929;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        text-align: left;
      }


      .user-info {
        text-align: left;
        margin-bottom: 5px;
      }

      .user-icon {
        display: inline-block;
      }

      .name-time {
        color: #2E2E2E;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        display: inline-block;
        position: relative;
        top: 10px;
        left: 10px;

        .date-time {
          font-size: 8px;
          letter-spacing: 0;
          line-height: 16px;
        }
      }
    }
  }


}

.info-section {
  text-align: left;

  .info-head {
    display: block;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    margin-bottom: 5px;
    font-weight: 500;
  }

  .info-value {
    color: #292929;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
  }

  .bg-green {
    background: #2ce68f;
    width: 73px;
    color: #292929;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 10px;
    text-align: center;
    display: inline-block;
    padding: 6px;
    font-weight: 500;
  }
}


.contents-panel-transactions {
  .MuiBox-root {
    padding: 24px 0;
  }
}



.vis-timeline {
  font-size: 12px;
  border: none;
  background: #f9f9f9;
  border: none !important;
}

.vis-grid.vis-vertical.vis-minor.vis-today {
  border-left: 1px dashed grey;
  border-top: 1px dashed grey;
  top: 0px !important;
}

.vis-item {
  text-align: left;
  height: 50px;
  font-weight: 100;
  background-color: transparent !important;
  border-color: transparent !important;
  border-width: 0 !important;
}

.vis-item.vis-range.default {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}

.vis-item.vis-range.even {
  background-color: #005092;
  border: 1px solid #005092;
  color: #FFFFFF;
}

.vis-item.vis-range.odd {
  background-color: #0067BC;
  border: 1px solid #0067BC;
  color: #FFFFFF;
}

.vis-item-content {
  position: relative;
  padding: 0 !important;
}

.vis-item-content .custom-content {
  position: unset !important;
}

.vis-item-content .custom-content .custom-content__title {
  padding-left: 10px;
}

.vis-item-content .custom-content .custom-content__status {
  width: 7px;
  height: 7px;
  position: absolute;
  top: 35%;
  left: 0;

}

.vis-item-content .custom-content .custom-content__status.custom-content__status--approved {
  background-color: #79CE24;
}

.vis-item-content .custom-content .custom-content__status.custom-content__status--pending-approval {
  background-color: #AAAAAA;
}

.vis-item-content .custom-content .custom-content__status.custom-content__status--rejected {
  background-color: #F45C32;
}


.vis-label {
  height: 40px;
  line-height: 35px;
  vertical-align: bottom;
}


.vis-grid.vis-vertical.vis-saturday,
.vis-grid.vis-vertical.vis-sunday {
  background: rgba(255, 21, 0, 0.05);
}

.vis-labelset .vis-label {
  border: none;
  top: 10px;
}

.vis-panel.vis-left {
  border: none;
}

.vis-item.vis-range {
  border-radius: 0;
}

.vis-foreground .vis-group {
  border-bottom: none !important;
}

.vis-time-axis .vis-grid.vis-minor {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.vis-panel.vis-bottom,
.vis-panel.vis-center,
.vis-panel.vis-left,
.vis-panel.vis-right,
.vis-panel.vis-top {
  border: 1px solid transparent !important;
}

.vis-time-axis .vis-grid.vis-minor {
  border-color: transparent !important;
}

.vis-time-axis .vis-grid.vis-major {
  border-color: transparent !important;
}

.vis-item.vis-range .vis-item-content {
  width: 100%;
}

.vis-item .vis-item-overflow {
  overflow: visible !important;
}

.timeline-content-td {
  padding: 0 !important;
}

.span-img-div span {
  display: inline-block;
  font-size: 10px;
  margin-left: 5px;
  width: 1000px;
}

.span-container {
  width: 100%;
}

.span-img-div img {
  float: left;
}

.button-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.Popover {
  z-index: 10;
}

.Popover-body {
  display: inline-flex;
  flex-direction: column;
  background: white;
  color: black;
  border-radius: 0;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
}

.Popover-tipShape {
  fill: white;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
}

.Target {
  -webkit-user-select: none;
  position: relative;
  display: inline-block;
  color: hsla(0, 0%, 0%, 0.45);
  color: white;
  white-space: pre-wrap;
  text-align: center;
  text-transform: uppercase;
  border-radius: 0.2rem;
  overflow: hidden;
}

.Target-Toggle {
  display: block;
  padding: 1rem;
  cursor: pointer;
  background: hsl(346, 62%, 55%);
}

.Target.is-open .Target-Toggle {
  background: hsl(346, 80%, 50%);
}

.popover-content {
  position: relative;
}

.popover-content__close-button {
  cursor: pointer;
  position: absolute;
  z-index: 1051;
  font-size: 14px;
  font-weight: 700;
  border: none;
  background: transparent;
  opacity: 0.2;
  line-height: 1;
  color: #000;
  top: 5px;
  right: 10px;
  outline: none;

}

.popover-content__description {
  padding: 20px 20px;
}

.text-decoration-none {
  text-decoration: none;
}

.span-bar {
  width: 100%;
  background: #081981;
  margin-top: 5px;
  height: 6px;
}

.filter-text-box {
  display: inline-block;
  width: 269px;
  height: 33px;
  line-height: 33px;
  margin-right: 16px;
  padding-left: "5px";

  .MuiOutlinedInput-notchedOutline {
    border: transparent;
    box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.15);
    height: 33px;
    color: #081981;
    letter-spacing: 0;
    top: 0;
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 14px) scale(1);
    pointer-events: none;
    font-size: 14px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 8px !important;
    color: #081981;
    height: 33px;
  }

  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
    padding: 0;
  }

  .filter-sec {
    width: 43px;
    color: #292929;
    font-size: 18px;
    letter-spacing: 0;
    line-height: 33px;
    float: left;
    padding-top:0.2rem;
    margin-right: 5px;
    text-shadow: 0 0 0 #292929;
  }

  .filter-text-input {
    display: inline-block;
    width: calc(100% - 48px);
    height: 33px;
  }
}


.table-view-graph {
  display: inline-block;
  width: 136px;
  line-height: 33px;
  border-left: 1px solid #081981;

  span.table-view-btn {
    display: inline-block;
    cursor: pointer;
    height: 33px;
    width: 136px;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: #081981;
    box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
    font-size: 16px;
    font-weight: bold;
    margin-left: 16px;
    padding: 0 4px;
  }
}

span.table-view-graph {
  border: transparent !important;
  box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.15);
  height: 40px;
  color: #081981;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 16px;
  display: inline-block;
  padding: 5px;
  width: 100%;
}

span.table-view-graph .MuiOutlinedInput-notchedOutline {
  border: none;
}

span.table-view-graph .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0;
}

span.table-view-graph .tableview-textBox {
  width: calc(100% - 120px);
  position: relative;
  top: -5px;
  float: right;
}

span.icon-box-text {
  position: relative;
  left: 5px;
}


.right-content {
  float: right;
  display: inline-block;
  margin-right: 80px;
}

.search-text-box {
  width: 215px;
  display: inline-block;
  height: 33px;
}

.search-text-box .MuiInputBase-input {
  width: 100% !important;
  color: #081981;
}

body.dataMap-table-filter .MuiAutocomplete-popper {
  width: 350px !important;
}

div[class^="makeStyles-popover"] {
  pointer-events: visible;
}

.bottomTrayEnable {
  background: #fff;
  overflow: hidden;
  color: #000;
  line-height: 50px;
  transition: all .5s ease-in-out;
  height: 0;
  position: absolute;
  bottom: 0;
  width: calc(100% - 228px);
  left: 210px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 20px 20px 0 0;

  .main {
    height: 100%;
    overflow-y: auto;
  }

  .inspect-contents {
    padding: 0;
    height: 100%;
    background-color: #FFF;

    // .node-name{
    //   width: max-content;
    //   margin-left: 10px;
    //   padding: 0px 10px;
    //   border-radius: 3px;
    // }

    .node-title {
      color: #292929;
      font-size: 19px;
      font-weight: 600;
      margin: 20px 0px 0px;
      &.oneDriveNodeTitle{
        margin: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    div.contents-panel {
      border-radius: 0;
      margin-top: 0;
      box-shadow: none;
      max-height: 100%;
    }

    .inspect-contents-header {
      //padding-top: 20px;
      box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.2) !important;
      background: #F9F9F9 !important;

      .header-bar {
        height: 7px;
        width: 80px;
        border-radius: 4px;
        background-color: #706F7E;
        content: '';
        z-index: 1;
        position: absolute;
        left: calc(50% - 35px);
        top: 10px;
        cursor: pointer;
      }

      .MuiTabs-indicator {
        background-color: #3f51b5 !important;
      }

    }

  }


  span[class^="PrivateTabIndicator-colorPrimary"] {
    background-color: transparent !important;
    height: 0;
  }

}

.bottomTrayEnable.bottomTrayEnableView {
  height: 565px;
}


#mouse-over-popover {
  pointer-events: visible;

  .tooltip-box {
    width: 280px;
    //height: 263px;
    background: #ffffff;

    .tt-header {
      //height: 53px;
      height: auto;
      border-bottom: 1px solid #ccc;
      padding: 8px;

      h6 {
        margin: 10px 0 0;
        color: #292929;
        font-family: monospace;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 22px;
        overflow-wrap: break-word;
      }


      span.alert-type {
        height: 20px;
        width: 46.67px;
        color: #FF2251;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 10px;
        text-align: center;
        background: #ffbcca;
        padding: 2px 5px;
      }

      .alert-badge{
        margin-left: 45px;
        cursor: pointer;
        &.high .MuiBadge-badge{
          color: #ff2251;
          background-color: rgb(255, 197, 209);
        }
        &.medium .MuiBadge-badge{
          color: #ff8421;
          background-color: rgb(255, 215, 182);
        }
        &.low .MuiBadge-badge{
          color: #081981;
          background-color: rgb(190, 195, 221);
        }
      }

      span.alert-no {
        margin-left: 5px;
        background: #ff2251;
        color: #fff;
        padding: 2px 8px;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 10px;
        text-align: center;
      }
    }

    .tt-body {
      padding: 11px 8px 6px 8px;

      p {
        margin: 5px 0;
        font-family: inherit;
        color: #292929;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
      }

      span.tables-list {
        border-radius: 4px;
        background-color: #DFE1E9;
        display: inline-block;
        padding: 8px;
      }
    }

    .border-top {
      padding: 8px;
      border-top: 1px solid #ccc;
    }

    .tt-footter {
      padding: 8px;
      border-top: 1px solid #ccc;

      p {
        margin: 0;
        color: #081981;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
      }

      .arrow {
        display: inline-block;
        float: right;
      }
    }
  }
}


.no-app-nodes {
  padding: 20px 0 20px 210px;
}


.MuiTabs-indicator {
  background-color: transparent !important;
}

.inspect-contents-header .MuiTabs-indicator {
  background-color: #3f51b5 !important;
}


.inspect-contents .MuiTableRow-root {
  height: auto !important;
}

span.icon-box {
  position: relative;
  width: 100%;
  display: inline-block;

  span.icon-box-drop {
    position: absolute;
    width: 100%;
    left: 0;

    .MuiInput-formControl {
      margin: 0;
    }

    .MuiFormControl-root {
      width: 100%;
    }

    .MuiInput-underline:before {
      border-bottom: none !important;
    }

    .MuiInput-underline:after {
      border-bottom: none;
    }

    .MuiSelect-select:focus {
      background: transparent;
    }

  }

}

.durvstar-loader-box {
  width: 100%;
  height: 5vh;
  position: absolute; 
  top: 20;
  left: 0;
  z-index: 50000;

  &.lessZindex{
    z-index: 0;
  }

  .durvstar-loader-gif {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.transcation-container table {
  min-height: 200px;

  tr:last-child {
    height: 0 !important;
  }
}



.euiFlyout {
  border-left: 1px solid #D3DAE6;
  box-shadow: 0 40px 64px 0 rgba(65, 78, 101, 0.1), 0 24px 32px 0 rgba(65, 78, 101, 0.1), 0 16px 16px 0 rgba(65, 78, 101, 0.1), 0 8px 8px 0 rgba(65, 78, 101, 0.1), 0 4px 4px 0 rgba(65, 78, 101, 0.1), 0 2px 2px 0 rgba(65, 78, 101, 0.1);
  border-color: #c6cad1;
  border-top-color: #e3e4e8;
  border-bottom-color: #aaafba;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  z-index: 3000;
  background: #FFF;
  display: -webkit-flex;
  display: flex;
}

.euiFlyout__closeButton {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  right: 17px;
  top: 17px;
  z-index: 3;
}

.euiFlyout--medium {
  min-width: 424px;
  width: 50%;
}

.euiFlyout--small {
  width: 90vw;
  min-width: 0;
  /* 1 */
  max-width: 403px;
}

.euiFlyoutBody {
  overflow-y: hidden;
  height: 100%;
}

.euiFlyoutBody .euiFlyoutBody__overflow {
  scrollbar-width: thin;
  height: 100%;
  overflow-y: auto;
}

.euiFlyoutBody .euiFlyoutBody__overflow::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

.euiFlyoutBody .euiFlyoutBody__overflow::-webkit-scrollbar-thumb {
  background-color: rgba(105, 112, 125, 0.5);
  border: 6px solid transparent;
  background-clip: content-box;
}

.euiFlyoutBody .euiFlyoutBody__overflow::-webkit-scrollbar-corner,
.euiFlyoutBody .euiFlyoutBody__overflow::-webkit-scrollbar-track {
  background-color: transparent;
}

.euiFlyoutBody .euiFlyoutBody__banner .euiCallOut {
  border: none;
  border-radius: 0;
  padding-left: 24px;
  padding-right: 24px;
}

.euiFlyoutBody .euiFlyoutBody__overflowContent {
  padding: 24px;
}

.euiFlyoutFooter {
  background: #F5F7FA;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  padding: 16px 24px;
}

.euiFlyoutHeader {
  -webkit-flex-grow: 0;
  flex-grow: 0;
  padding: 24px 40px 0 24px;
}

.euiFlyoutHeader--hasBorder {
  padding-bottom: 24px;
  border-bottom: 1px solid #D3DAE6;
}

.euiFlyoutHeader {
  -webkit-flex-grow: 0;
  flex-grow: 0;
  padding: 24px 40px 0 24px;
}

.euiFlyoutHeader--hasBorder {
  padding-bottom: 24px;
  border-bottom: 1px solid #D3DAE6;
}

.euiFlyout__closeButton {
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  right: 17px;
  top: 17px;
  z-index: 3;
}


.euiButtonIcon {
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: center;
  white-space: nowrap;
  max-width: 100%;
  vertical-align: middle;
  font-family: "Inter UI", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-weight: 400;
  letter-spacing: -.005em;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-kerning: normal;
  font-kerning: normal;
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5;
  text-decoration: none;
  border: solid 1px transparent;
  transition: all 250ms ease-in-out;
  border: none;
  background-color: transparent;
  box-shadow: none;
  height: auto;
  min-height: 24px;
  min-width: 24px;
  line-height: 0;
  padding: 4px;
  border-radius: 4px;
}



.traceid-list {
  padding: 15px;
  min-height: 60px;

  .traceid-list-view {
    float: right;

    span {
      display: inline-block;
      width: 24px;
      line-height: 26px;
      vertical-align: top;
      cursor: pointer;
      font-size: 16px;
    }
  }
}

.timeline-content.view-false {
  height: 0;
}

th.MuiTableCell-root.MuiTableCell-head {
  position: relative;
}

th.MuiTableCell-root.MuiTableCell-head:after {
  height: 90%;
  width: 1px;
  opacity: 0.5;
  border-radius: 0.5px;
  background-color: #081981;
  position: absolute;
  right: 1px;
  top: 5%;
}

th.MuiTableCell-root.MuiTableCell-head:last-child:after {
  width: 0;
}

.inspect-oneDriveImage{
  width: 40px;
  height: 40px;
}

.inspect-title-header{
  display: flex;
  align-items: center;
  background-color: #ffff;
  &.dataOwner{
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 60px;
  }
}

.inspect-title{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  max-width: 260px;
  margin-left: 2%;
  margin-top: 1%;
  gap: 20px;
  &.all-files-list{
    padding: 10px;
    margin-left: 0px;
  }
  &.location {
    margin-top: 0px;
    margin-left: 0px;
  }
}

.inspect-text{
  font-size: 14px;
}

.inspect-dataSource{
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 10px
}

.inspect-creationTime{
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 10px;
  gap: 10px;
  &.location {
    margin-left: 40px;
    margin-right: 10px;
  }
}

.inspect-alerts{
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-top: 10px;
  line-height: 1.6rem;
  &.sensitivity{
    margin-left: 20px;
  }
  &.dataOwner{
    margin-top: 0px;
    margin-left: 0px;
  }
  &.summary{
    margin-left: 25px;
  }
}
.inspect-file-header{
  display: flex;
  align-items: center;
  gap: 30px;
  white-space: normal;
  &.dataOwner{
    gap: 0px;
  }
}
.inspect-title-right{
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  padding-right: 22px;
  &.dataOwner{
    justify-self: flex-start;
    margin-top: 15px;
    align-items: center;
  }
  &.location {
    align-items: baseline;
  }
}
.inspect-file-fieldName{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  &.dataOwner{
    width: 100px;
    font-family: "Nunito-Regular";
    font-weight: 700;
  }
}
.inspect-file-severity{
  width: max-content;
  padding: 6px;
  line-height: 0.8rem;
  border-radius: 2px;
  &.file-pop-up{
    display: inline-block;
    font-size: 12px;
    font-weight: 700;
  }
  &.CRITICAL{
    color: #ff2251;
    background: #febbcb;
  }
  &.HIGH{
    color: #ff2251;
    background: #febbcb;
  }
  &.MEDIUM{
    color: #ff8421;
    background: #fde5d3;
  }
  &.LOW{
    color: #081981;
    background: #A8D5FF;
  }
  &.NOT_PROCESSED{
    color: #5D0A90;
    background-color: rgb(255, 196, 255);
  }
}
.oneDrive-inspect-box{
  .MuiBox-root{
    padding: 0;
  }
}
.dataOwnerSensitivity{
  display: flex;
  flex-direction: column;
}
.dataOwnerNoOfFiles{
  display: flex;
  align-items: center;
  margin-left: 20px;
  gap: 5px;
  font-size: 14px;
  &.dataOwnerTableData{
    margin-left: 15px;
    font-weight: 600;
    font-size: 12px;
  }
}
.dataOwnerFiles{
  display: flex;
  align-items: center;
}
.dataOwnerColor{
  font-weight: 700;
  font-size: 15px;
  overflow: hidden;
  max-width: 50px;
  text-overflow: ellipsis;
  &.table{
    font-family: "Nunito-Regular";
    font-weight: 700;
  }
  &.high{
    color: #FF2251;
  }
  &.medium{
    color: #FF8421;
  }
  &.low{
    color: #081981;
  }
  &.nonProcessed{
    color: #5D0A90;
  }
}

.dataOwnerSensitive{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dataOwnerSensitiveHeading{
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  gap: 5px;
}