.MDRbarGraph{
    box-sizing: border-box;
    height: 36px;
    width: 550px;
    border: 1px solid #E5E5E5;
    border-radius: 22px;
    background-color: #E5E5E5;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    cursor: pointer;
    .active{
        height: 32px;
        min-width: 255px;
        width: 100%;
        color: #ffffff;
        border-radius: 22px;
        background-color: #BC038B;
        box-shadow: 0 5px 8px -3px rgba(1,18,121,0.5);
        padding-top:6px;
        color: #E5E5E5;
    }
    .inactive{
        text-align: center;
        box-sizing: border-box;
        color: gray;
        height: 250px;
        min-width: 225px;
        width: 100%;
        border-radius: 22px;
        padding:2px;
        padding-top: 6px;
        padding-left: 23px;
    }
}


.MDR_squareTogglers{
    
height: 32px;
width: 360px;
border-radius: 4px 0 0 4px;
box-shadow: 0 0 8px -3px rgba(1,18,121,0.5);
margin-bottom: 20px;
text-align: center;
display: flex;
cursor: pointer;
justify-content: space-between;
color:#BC038B;

.active{
    box-sizing: border-box;
    height: 32px;
    width: 120px;
    background-color: #BC038B;
    color: #ffffff;
    padding:5px;
    // padding:10px 10px  0px 10px;
}
.inactive{
    padding:5px;
    height: 32px;
    width: 120px;
}

}

