.main-container{
    height: 920px;
    overflow-y: scroll;
    padding:0px 23px 0px 211px;
    display: flex;
}
.getting-started-container{
    height: 273px;
    //width: calc(100% - 200px);
    margin: 24px 24px 12px 205px;
    color: $white;
    background-image: url('../../../../assets/vizr_images/BG.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .review-container{
        display: flex;
        .review-list{
            display: grid !important;
            padding: 20px 20px;
            span{
                display: inline-flex;
                margin-bottom: 9px;
            }
        }
    }
    .getting-started-title{
        width: 100%;
        height: 50px;
        margin-left: 25px;
        margin-right: 10px;
        border-bottom: 1px solid $white;
    }
    .small-text{
        font-size: 100%;
        padding-top: 4px;
        &.selected{
            font-weight: bold;
        }
    }
}
.main-content{
    height: 1000px;
    //width: 85%;
    margin: 24px 24px 12px 211px;
}
.history-list{
    //height: 709px;
    //width: 593px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.3);
    max-height: 640px;
    overflow-y: scroll;
    .history-date{
        height: 50px;
        padding: 10px;
        background-color: #F9F9F9;
        font-family: $font-family-base;
    }
    .history{
        background: $white;
        width: 100%;
        overflow: hidden;
        padding-top: 10px;
        padding-left: 10px;
        font-family: $font-family-base;
        p{
            padding-top: 5px;

        }
        img{
            margin-bottom: 1rem;
            filter: invert(100%);
        }
    }
    
  }