//Nunito-Black
@font-face {
	font-family: 'Nunito-Black';
	src: url('../../../fonts/Nunito/Nunito-Black.ttf');
}

//Nunito-BlackItalic
@font-face {
	font-family: 'Nunito-BlackItalic';
	src: url('../../../fonts/Nunito/Nunito-BlackItalic.ttf');
}

//Nunito-Bold
@font-face {
	font-family: 'Nunito-Bold';
	src: url('../../../fonts/Nunito/Nunito-Bold.ttf');
}

//Nunito-BoldItalic
@font-face {
	font-family: 'Nunito-BoldItalic';
	src: url('../../../fonts/Nunito/Nunito-BoldItalic.ttf');
}

//Nunito-ExtraBold
@font-face {
	font-family: 'Nunito-ExtraBold';
	src: url('../../../fonts/Nunito/Nunito-ExtraBold.ttf');
}

//Nunito-ExtraBoldItalic
@font-face {
	font-family: 'Nunito-ExtraBoldItalic';
	src: url('../../../fonts/Nunito/Nunito-ExtraBoldItalic.ttf');
}

//Nunito-ExtraLight
@font-face {
	font-family: 'Nunito-ExtraLight';
	src: url('../../../fonts/Nunito/Nunito-ExtraLight.ttf');
}

//Nunito-ExtraLightItalic
@font-face {
	font-family: 'Nunito-ExtraLightItalic';
	src: url('../../../fonts/Nunito/Nunito-ExtraLightItalic.ttf');
}

//Nunito-Italic
@font-face {
	font-family: 'Nunito-Italic';
	src: url('../../../fonts/Nunito/Nunito-Italic.ttf');
}

//Nunito-Light
@font-face {
	font-family: 'Nunito-Light';
	src: url('../../../fonts/Nunito/Nunito-Light.ttf');
}

//Nunito-LightItalic
@font-face {
	font-family: 'Nunito-LightItalic';
	src: url('../../../fonts/Nunito/Nunito-LightItalic.ttf');
}

//Nunito-Regular
@font-face {
	font-family: 'Nunito-Regular';
	src: url('../../../fonts/Nunito/Nunito-Regular.ttf');
}

//Nunito-SemiBold
@font-face {
	font-family: 'Nunito-SemiBold';
	src: url('../../../fonts/Nunito/Nunito-SemiBold.ttf');
}

//Nunito-SemiBoldItalic
@font-face {
	font-family: 'Nunito-SemiBoldItalic';
	src: url('../../../fonts/Nunito/Nunito-SemiBoldItalic.ttf');
}

