@import "../admin-styles/custom/variables";



  .signup_header{
    height: 5.5rem;
    width: 100%;
    padding-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
    align-content: center;
    // margin-top: 80px;
    background: linear-gradient(180deg, rgba(241,242,246,0) 0%, #DFE1E9 100%);
    &-wrapper{  
           margin-left: 13.5rem;
           margin-top: 1rem;
    }
}
.signup_header.signup_header_margin{
  height: 5.5rem;
  margin-top: 4rem;
}
.signup_header_margin_breadCrumbs{
  height: 5.5rem;
  margin-top: 5rem;
  padding-bottom: 6rem;

}
.SignUppage_header_text{
  height: 40px;
  width: 586px;
  color: #292929;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  margin-bottom: 0.5%;
}
.SignUppage_header_title{
  height: 10px;
  color: #292929;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: -25px;
}

.signupPage {
  top: 0;
  left: 0;
  background-color:#FFFFFF;
  height: 130vh;
  position: relative;
  z-index: 998;
  width: 100%;

}
.signupPageBlur-hidden{
  top: 0;
  left: 0;
  // background-color: #DFE1E9;
  background-image: linear-gradient(to right,#DFE1E9,#DFE1E9);
  opacity: 0.5;
  height: 190%;
  position: absolute;
  z-index: 998;
  width: 100%;
  // border:1px solid red;
  display:none
}
.signupPageBlur-visible{
  top: 0;
  left: 0;
  // background-color: #DFE1E9;
  background-image: linear-gradient(to right,#DFE1E9,#DFE1E9);
  opacity: 0.5;
  height: 190%;
  position: absolute;
  z-index: 998;
  width: 100%;
  // border:1px solid red;
  display:visible

}
.signUpconatainer{
    height: 56px;
    width: 268px;
    margin-top:40px;
  &.platFormSignUp{
    margin-top: 15px;
  }

}

.signUpDBConfigContainer{
  width: 330px;
  margin-top:30px;
  .MuiFormGroup-root {
    flex-direction: row;
  }
  .MuiSvgIcon-fontSizeSmall{
    font-size: 1rem;
  }
}

.errorMsg {
  color: red;
  margin-top: "20px";
  font-size: 12px;
}

.signup{
    height: 32px !important;
    width: 320px !important;
    border:1px solid grey !important;
    border-radius: 0.2rem;
    margin-top: 0.8rem;
    box-sizing: border-box;

   
}
.signup_inputField{
  height: 2rem !important;
  width: 21rem !important;
  box-sizing: border-box;
  border: 1px solid #DFE1E9;
  border-radius: 0.25rem;
  background-color: #FFFFFF;
  margin-top: 0.8rem;
  padding-left: 10px;

}
.signUp_labelName{
  height: 1rem;
  width: 7rem;
  color: #292929;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  // margin-bottom: 2rem;
}
.signUpGrid_container{
    border:1px solid lavender !important;
    margin-top: 40px;
    margin:auto;
    height: 100%;
    margin-left: 13.5rem;
    margin-top: 3rem;
    // min-width:80% !important;
    width:82% !important;
    border-radius: 0.2rem;
    .signUpGrid_left{
        margin-left: 2rem !important;
    }
}
.SignUpHeaderCommon{
  height: 1.375rem;
  width: 15rem;
  color: #292929;
  font-size:1.2rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.375rem;
}
.signupinput_header{
   
    margin-top: 2rem;
    // margin-left: 0px !important;

}

.signup_select_header{

    margin:33.31px 10px 0px;
    margin-bottom: 2.5rem;


}
.signup_select_container{
    // margin-top: 2.5rem !important;
    margin-bottom: 0.2rem !important;
     font-weight: bold;
    position: relative;

}
.signUp_selectandCheckBoxHeader{
  display: flex;
  justify-content: flex-start;
  align-items:center
}
.signup_select{
    height: 2rem !important;
    width: 20rem !important;
    opacity: 0.7;
    color: #292929;
    font-size: 0.8rem;
    letter-spacing: 0;
    line-height: 1rem;
    border-radius: 3px;
    margin-top: 0.4rem;
    margin-bottom: 2rem;
    // margin-left: 2.5rem;
    border: 1px solid #DFE1E9;
}
.signup_select_options{
  height: 40px;
  width: 319px;
  background-color: #F9F9F9;

}
.select_DropDown{
  height: 141px;
  width: 319px;
  border:1px solid red;
  position: absolute;
 background-color: #FFFFFF;
 z-index: 1;
 top:3.7rem;

}

.signUp_footer{
    padding-left: 10%;
    height: 92px;
    // width: 100%;
    transform: scale(1);
    margin-top: 10rem;
    padding-bottom: 0.5rem;
    background-color: "#FFFFFF";
    box-shadow: 0 -2px 2px 0 rgba(0,0,0,0.3);
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

.signUp_footerCheckbox{

    cursor:pointer;
    padding-top: 1.5rem;
    padding-right: 1rem;
  }

.signUp_footerPopUp{
    color: #2947FD;
    text-decoration: none;
    margin-left: 1px;
    cursor: pointer;
}


.signUp_Button {
    height: 44px;
    width: 130px;
    border-radius: 4px;
    background-color: #2947FD; 
    box-shadow: 0 5px 8px -3px rgba(41,71,253,0.5);
    margin-left:1rem !important;
  }
  .signUp_ButtonDisabled{
    height: 44px;
    width: 130px;
    border-radius: 4px;
    color:white;
    opacity: 0.5;
    box-shadow: none;
    margin-left: 1rem !important;
  }
.signUp_License {
    min-height: 450px;
    max-height: 100%;
    width:757px;
    border-radius: 3px;
    // max-width: 100%;
    top: 23.7rem;
    left: 20rem;
    position: absolute;
    z-index: 1000;
    opacity:1 !important;
    background-color: #ffffff;
    padding-top:2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    display: none;
  }
.signUp_cross{
  position: absolute;
  top:2rem;
  right:2.5rem;
  cursor: pointer;
  font-size: 1.5rem;
}

.signUp_LicenseHeading{
    height: 27px;
    width: 68px;
    color: #292929;
    font-size: 20px;
    letter-spacing: 0;
    line-height: 27px;
  }
.signUp_LicensePointsContainer {
    height: 285px;
    width: 697px;
    color: #292929;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
.signUp_LicensePoints {
    width: 700px
  }

.SignUpFooterCommonStyle{
  height: 21px;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;

}


