.quick-link-wrapper.no-margin{
    color: white;
    margin-top: 0.5rem;
    h4{
        font-weight: normal;
        margin: 5px;
    }
    .quick-link-container,.shrink-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        .divider{
            margin: 10px;
            border: 1px solid #ffffff;
            width: 80%;
        }
        .item{
            flex: 1;
            cursor: pointer;
            img{
                height: 45px;
            }
        }
    }
    .shrink-container{
        .item{
            img{
                height: 34px;
            }
        }
    }
}
.quick-link-wrapper{
    margin-top: 5rem;
    color: white;
    h4{
        font-weight: normal;
    }
    .quick-link-container,.shrink-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        .divider{
            margin: 10px;
            border: 1px solid #ffffff;
            width: 80%;
        }
        .item{
            flex: 1;
            cursor: pointer;
            img{
                height: 55px;
            }
        }
    }
    .shrink-container{
        .item{
            img{
                height: 34px;
            }
        }
    }
}