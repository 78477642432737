@import "../../assets/scss/admin-styles/custom/variables";

.policy-toggle {
    .header_toggler {
      margin-left: 0rem;
    }
    .header_toggler_active, .header_toggler_inactive {
        padding: 10px 35px;
    }
    display: flex;
    flex-direction: row;
}

.policy-content {
  display: flex;
  gap: 10px;
}