.checkbox-root {
    &.hover {
      background-color: transparent;
    };
    &.MuiIconButton-label {
      justify-content: none;
    }
}
.checkbox-icon {
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    border: 1px solid #DFE1E9;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 3px 6px -3px rgba(41,71,253,0.5);

    //border-radius: 3px;
    //width: 24px;
    //height: 21px;
    //box-shadow: inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1);
    //background-color: #f5f8fa;
    //background-image: linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0));
    &.Mui-focusVisible & {
      outline: 2px auto rgba(19,124,189,.6);
      outline-offset: 2;
    };
    input:hover ~ & {
      background-color: #ebf1f5;
    };
    input:disabled ~ & {
      box-shadow: none;
      background: rgba(206,217,224,.5);
    };
  }
  .checked-icon {
    box-sizing: border-box;
    height: 23px;
    width: 23px;
    border-radius: 4px;
    background-color: #F9FFFC;
    //box-shadow: 0 3px 6px -3px rgba(41,71,253,0.5);
    background-image: url('../../../Images/inspect/02.\ Active.svg');
     &.before {
      display: 'block';
      background-image: url('../../../Images/inspect/02.\ Active.svg');
    }
    input.hover ~ & {
        background-color: #106ba3;
    }
    input:disabled ~ & {
      background: none;
      background-image: url('../../../Images/inspect/disabledCheckIcon.svg');
    };
}


.chip-replica{
    justify-content: space-between;
    border: none;
    cursor: default;
    height: 40px;
    display: flex;
    outline: 0;
    padding: 0;
    font-size: 0.8125rem;
    box-sizing: border-box;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    align-items: center;
    font-family: 'Nunito', sans-serif;
    white-space: nowrap;
    border-radius: 16px;
    vertical-align: middle;
    text-decoration: none;
    background-color: #e0e0e0;
    p{
      text-overflow: ellipsis;
      overflow: hidden;
      margin-top: 16px;
      margin-right: 10px;
      //width: 175px;
    }
    &.edit{
      padding: 0px 10px;
    }
    &.high{
      padding: 0px 10px;
      background-color: #ff2251 !important;
    }
    &.medium{
      padding: 0px 10px;
      background-color: #ff8421 !important;
    }
    &.low{
      padding: 0px 10px;
      background-color: #1a2983 !important;
    }
}