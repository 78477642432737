@import "../../../assets/scss/admin-styles/custom/variables";

.togglerandSearch_Conatiner {
  .useraccessRights_content {
  }
  .hide {
    visibility: hidden;
  }
  .unhide {
    visibility: visible;
  }
}

.full-ht {
  height: 100%;
}