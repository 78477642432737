#name{
    width: 100%;
    height: 51px !important;
    font-size: 32px;
    padding-left: 10px;
    margin-bottom: 6px !important; 
}  
#description{
    width: 100%;
    padding-left: 10px;
    height: 64px !important;
    font-size: 16px; 
}
#action{
    width: 80%;
    height: 64px;
}  
p{
    //font-family: $font-family-base;
    font-size: $font-size-lg;
}
.auto-response-container{
    width: 98%;
    border-radius: 5px; 
    border: dashed 1px rgba(11,11,244,0.23);
    background-color: rgba(152,196,255,0.12);  

    .autoResponseInputField{
        font-size: 16px;
        padding-left: 10px;
        background: transparent;
        border: none
    }
    .autoResponseInputField::placeholder{
      font-size: 14px;
       width: 100%;
    }
    .checkbox-icon{
        width: 22px !important; 
    }   

}
.data-input{
    width: 749px;
    border-radius: 5px; 
    border: dashed 1px rgba(11,11,244,0.23);
    background-color: rgba(152,196,255,0.12);
    
}
.add-create-flow-group{
    box-sizing: border-box;
    height: 192px;
    padding: 80px 45px;
    border-radius: 5px; 
    border: dashed 1px rgba(11,11,244,0.23);
    background-color: rgba(152,196,255,0.12);
    margin-top: 25px;
    small{
        margin-left: 380px;
        text-align: center;
        cursor: pointer;
    } 
}
.main-edit-create-container .response-action {
    padding: 8px 0 21px 0;
    border-bottom: 1px solid rgba(0,0,0,0.2);
}
.main-edit-create-container{
    height: 100%;
    // overflow-y: scroll;

    &.create-edit-policy{
        padding:0px 23px 0px 211px;
        display: flex;
        height:100%;
        min-height: 162vh;
    }
    // padding:0px 23px 0px 211px;
    // display: flex;
}
.MuiNativeSelect-select.MuiNativeSelect-select {
    padding: 0;
}
.MuiNativeSelect-select.MuiNativeSelect-select {
    padding-right: 0px !important;
    padding-left: 3px;
}
.create-edit-chart-container{
    border-radius: 4px;
    margin-top: 24px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    background-color: $white;
    .MuiIconButton-root {
        display: none;
    }
}
.save-button{
    display: flex;
    flex-direction: column;
    width: min-content;
    margin-left: 30px; 
    margin-bottom: 8px;
}

.grid-9{
    margin-top: 0.8rem !important ;
    padding: 25px 10px 0px 0px !important;
    border-right: 1px solid lightgray;
}
.grid-3{
    padding-left: 25px;
    padding-top: 20px;
}
.name-form-width{
    width: 80%;
}
.MuiToggleButton-root.Mui-selected{
    color: $white !important;
    background-color: $royal !important;
}
.policy-description{
        font-family: $font-family-base;
        min-height: 133px;
        display: grid;
        margin: 0px 0 24px;
        padding: 12px 0px 10px 10px;
        background: linear-gradient($pale-grey-0,$pale-grey);
        p{
            margin: 0;
            margin-top: 10px;
        }
}
.group-4{
    height: auto;
    width: max-content;
    border-radius: 4px;
    background-color: #DFE1E9;
    padding: 5px;
}
.auto-response{
    margin:10px 10px 0px 10px;
    height: 40px;
    width: 180px;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow: 0 5px 8px -3px rgba(1,18,121,0.5);  
}
.comments-list-arr {
    margin-top: 50px;

.comments-list {
    border-radius: 4px;
    background-color: #DFE1E9;
    padding: 12px;
    margin-bottom: 15px;
    .comments-text {
        color: #292929;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        text-align: left;
    }
    
    
    .user-info {
        text-align: left;
        margin-bottom: 5px;
    }
    
    .user-icon {
        display: inline-block;
    }
    
    .name-time {
        color: #2E2E2E;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        display: inline-block;
        position: relative;
        top: -4px;
        left: 10px;
        .date-time {
            font-size: 8px;
            letter-spacing: 0;
            line-height: 16px;
        }
    }
}
}
.MuiSlider-thumb.Mui-disabled{
    width: 11px !important;
    height: 13px !important;
}
