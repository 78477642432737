.userEditing_Container {
  .userEditing_ImageContainer {
    box-sizing: border-box;
    height: 13rem;
    width: 13.5rem;
    border: 1px dashed rgba(11, 11, 244, 0.23);
    border-radius: 9rem;
    color: black;
    cursor: pointer;
    position: relative;
    margin-left: 5rem;

    .img {
      height: 12rem;
      width: 12rem !important;
      opacity: 0;
      cursor: pointer;
      z-index: 1;
      position: relative;
    }

    .uploaded_image {
      height: 13rem;
      width: 95% !important;
      border-radius: 6rem;
      position: absolute;
      left: 3%;
    }
    .name_text {
      height: 12rem;
      font-size: 5rem;
      width: 100% !important;
      position: absolute;
      color: #081981;
      left: 3.5rem;
      top: 2.2rem;
      display: inline;
    }
  }

  .disabled {
    background-color: lightgray;
  }
}

.randomStyle {
  box-sizing: border-box;
  height: 2rem;
  width: 75%;
  min-width: 50%;
  border: 1px solid #dfe1e9;
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 10px;
}
.randomStyle:focus {
  box-sizing: border-box;
  height: 2rem;
  width: 75%;
  min-width: 50%;
  border: 1px solid grey !important;
  border-bottom: 1px solid grey !important;
  border-radius: 4px;
  outline: none;
}

.UserEditing_input {
  // height: 40px;
  .InputTextFeild {
    box-sizing: border-box;
    height: 2rem;
    width: 100%;
    border: 1px solid #dfe1e9;
    border-radius: 4px;
    background-color: #ffffff;
    padding-left: 10px;
  }
  .InputTextFeild:focus {
    box-sizing: border-box;
    height: 2rem;
    width: 100%;
    border: 1px solid grey !important;
    border-bottom: 1px solid grey !important;
    border-radius: 4px;
    outline: none;
  }
  .InputSelectFeild {
    box-sizing: border-box;
    height: 2rem;
    // min-width: 25%;
    // width: 100%;
    border: 1px solid #dfe1e9;
    border-radius: 4px;
    background-color: #ffffff;
    content: none;
    outline: none !important;
    padding-left: 10px !important;
  }
  .disabled {
    background-color: lightgray;
  }
  .InputSelectFeild::selection {
    background-color: #fff;
  }
  .InputSelectFeild:before {
    display: none;
    background-color: #ffffff;
  }

  .InputSelectFeild:after {
    display: none;
    background-color: #ffffff;
  }
}

.editUser_resetPasswordFlex {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  margin-top: 15%;
  width: max-content;
}
.hide {
  visibility: hidden;
}
.unhide {
  visibility: visible;
}
.generate-save {
  left: 4%;
}
