@import "../../../assets/scss/admin-styles/custom/variables";

.useraccessRights_main_container {
  min-height: 60rem !important;
  height: 100%;
  .description {
    height: 105px;
    font-family: Nunito-Regular;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 24px;
    padding-left: 210px;
    background: linear-gradient(rgba(241, 242, 246, 0), #dfe1e9);
  }
}
 
.togglerandSearch_Conatiner {
  display: flex;
  flex-direction: row;
  padding-right: 64px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}
