.tenantSignUpParentContainer {
  display: flex;
  flex-direction: row;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  .MuiGrid-container{
    width: auto;
  }
}

.tenantContentBorder {
  border: 1px solid #D9D9D9;
}

.tenantSignUpContainer {
  margin-left: 8%;
  height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.pageSeparator {
  border: 1px solid #D9D9D9;
}
.tenantSignUpGridContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 0 16px #DCDCDC;
  border-radius: 5px;
  max-width: 32% !important;
}
.tenantSignUpGridChildContainer {
  padding-bottom: 20px;
}