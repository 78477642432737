.gridHeaders{
    height: 22px;
    width: 123px;
    color: #292929;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
    &.tenantSignUp{
      margin-left: 8%;
    }
}
.tenenantsignup_select{
    box-sizing: border-box;
    height: 2rem;
    width: 20rem;
    border: 1px solid #DFE1E9;
    border-radius: 4px;
    background-color: #FFFFFF;
}

.imgButton {
    height: 2rem;
    width: 84px;
    border-radius: 4px;
    background-color: #FFFFFF;
    border: 1px solid white !important;
    box-shadow: 0 5px 8px -3px rgba(1,18,121,0.5);
    cursor: pointer;
    position: absolute;
    top: 2rem;
    left: 15rem;
  }
  
.imgButton:focus {
    height: 2rem;
    width: 84px;
    border-radius: 4px;
    background-color: #FFFFFF;
    border: 2px solid white !important;
    box-shadow: 0 5px 8px -3px rgba(1,18,121,0.5);
    cursor: pointer;
    position: absolute;
    top: 2rem;
    left: 15rem;
    outline:none !important
  }

.imageUploadContainer {
    box-sizing: border-box;
    height: 121px;
    width: 224px;
    border: 1px dashed rgba(11,11,244,0.23);
    border-radius: 2px;
    background-color: rgba(152,196,255,0.12);
    color: black;
    cursor:pointer;
    position: relative;
  }
  .img{
    height: 121px;
    width: 50%;
    opacity: 0;
    cursor: pointer;
    z-index: 10;
  }
.signUp_uploadedImage {
    box-sizing: border-box;
    height: 121px;
    width: 100%;
    border-radius: 2px;
    color: black;
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
  
  }

.tenantSignUpFooter{
  padding-left: 10%;
  padding-top: 30px;
  padding-bottom: 24px;
  height:10%;
  margin-left:-33px;
  transform: scale(1);
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: "#FFFFFF";
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.tenant-edit-footer{
  height:10%;
  margin-left:-33px;
  transform: scale(1);
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: "#FFFFFF";
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}
.tenantPopup{
  height: 450px;
  width:757px;
  top: 10rem;
  left: 10rem;
  position: absolute;
  z-index: 1000;
  background-color: #ffffff;
  padding-top:2rem;
  padding-left: 2rem;
  display: none;
}
.signUpPointer {
  cursor: pointer;
}
.signUpNotAllowed {
  cursor: not-allowed;
}
.onboarding-text{
  height: 40px;
  width: 75%;
  color: #292929;
  font-family: Nunito-Regular;
  font-size: 32px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 40px;
  padding-bottom: 2rem;
}
.tenantRegions {
  .MuiFormControl-fullWidth {
    background: #fff;
    width: 84%;
  }
}