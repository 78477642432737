.Devops_initialSetUpContainer{
    min-height: 80rem;
    height: 100%;
    overflow-x: hidden !important;
    background-image: url("../../../vizr_images/combinedBackground.svg");
    background-repeat: no-repeat;
    background-position:  50% 72%;

}
.Devops_initialSetUpContaine::-webkit-scrollbar {
    display: none !important;
  }
  

.Devops_platfrom_Instructions{
    margin-left: 5%;
    width: 60%;
    // opacity: 1;
    // z-index:10;
    &.dataSource {
        width: 100%;
        margin-left: 1%;
      }
}
pre{
    word-wrap: break-word;
    white-space: initial;
    overflow:hidden;
}
.Devops_padding{
    padding-left: 14.5% !important;
    z-index: 10;
    opacity: 1;

    

    .marginRight{
        margin-right: 4%
    }
    .marginLeft{
         margin-left: 4%;
       
    }
}
.devopsInitialSetUp_Popup_Container{
    display: flex;
     margin-left: 54%;
     margin-top: 5%;
  &.dataSourcePlatform{
    display: block;
  }
}

.InitialSetup_header{
    width: 100%;
    padding-top: 24px;
    padding-left: 210px;
    padding-bottom: 2%;
    background: linear-gradient(180deg, rgba(241, 242, 246, 0) 0%, #DFE1E9 100%);
}

.InitialSetup_contentAgent    {
    height: 68px;
    // max-width: 75%;
    // min-width: 10%;
    width: calc(100% - 4rem);
    border-radius: 6px;
    background-color:#ffffff;
    opacity: 1;
    z-index: 20;
    box-shadow: 0 6px 8px 0 rgba(0,0,0,0.2);
    margin-top: 3%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.InitialSetup_contentAgent_Success{
    background-color: #2CE68F;
    height: 68px;
    // max-width: 75%;
    // min-width: 10%;
    width: calc(100% - 4rem);
    border-radius: 6px;
    opacity: 1;
    box-shadow: 0 6px 8px 0 rgba(0,0,0,0.2);
    margin-top: 3%;
    // margin-left: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.button{
    height: 44px;
  width: 180px;
  border-radius: 4px;
  background-color: #2947FD;
  box-shadow: 0 5px 8px -3px rgba(41,71,253,0.5);
}
.initialSetUp_AgentSelection{
    //  padding-left: 15rem;
       width: 67%;
       margin-top: 2rem;
    
}
.initialSetUp_platformSelection{ 
     display: flex;
     width: 99%; 
     margin-top: 2rem;
     margin-left: 2%;
     align-items:flex-start;
      &_leftContainer{
        position: relative;
        // min-width:30%;
         height:100%;
  
         cursor: pointer !important;
         &_ulList{
              padding-top: 10%;
              padding-left: 0% !important;
             }
     }
     &_rightContainer
     {
         display: flex;
         flex-direction: column;
         margin-left: 1% !important;
         width:75% !important;
         align-items:flex-end !important;
         align-content:center;
         text-align: center;
         position: absolute;
         left:15%;
         top:47%;

         &_heading{
            height: 44px;
            width: 360px;
            color: #292929;
            font-family: Nunito-SemiBold;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 22px;
            text-align: center;
            margin-bottom: 1rem;
         }
         &_text{
            height: 48px;
            width: 463px;
            color: #292929;
            font-family: Nunito-SemiBold;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 12px;
            text-align: center;
         }
       }
}

.platformInstructions_numbering{
    height: 26px;
    width: 26px;
    color: #FFFFFF;
    padding: 2px;
    padding-left: 0.9% !important;
    border-radius: 20px;
    box-sizing: border-box;
    background-color: #081981;
    text-align: center;
}
.platformInstructions_line{
    box-sizing: border-box;
    height: 95%;
    width: 2px;
    margin-top: 5% !important;
    border: 1px solid #979797;
    background-color: #979797;
}

.platformInstructions_stepname{
    height: 20px;
    width:100%;
    color: #292929;
    font-family: ".SF NS Display";
    font-size: 16px;
    letter-spacing: 0;
    line-height: 20px;
}
.platformInstructions_heading{
    height: 48px;
    width: 100%;
   color: #611010;
  font-family: ".SF NS Display";
  font-size: 12px;
  letter-spacing: 0;
  text-align: start;    
  line-height: 16px;
}
// .platformInstructions_content.platformInstructions_content_unmasked{
//     min-width: 35rem;
//     max-width: 40%;
// }
.platformInstructions_content{
    min-height: 10rem;
    max-height: 100%;
    // max-width: 85%;
    // width:90%;
    //width: calc(100% - 100px);
    max-width: 99%;
    // max-width: 39rem;
    display: flex;  
    flex-direction: column;
    margin-bottom: 5%;
    border-radius: 6px;
    background-color: #DFE1E9;

    &_heading{
        height: 31px;
        border-radius: 6px 6px 0 0;
        background-color: #C4C6CD;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
    }
    &_points{
        display: flex;
        flex-direction: column;
        //width:90%;
        align-items: flex-start;
        padding-left:3rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        height: 100%;
        word-wrap:break-word;
    }
    &.dataSource {
        background-color: #CEEAFF;
    }
}

.initial-setup-data{
    width: 88%;
    &.dataSourceloc{
        width: 100%;
        margin-right: 1rem;
    }
}

.flex1{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    margin-right: 8%;
}

.flex2{
    display: flex;
    flex-direction: column;
    justify-content:flex-start; 
    align-items: flex-start;
}

.grid{
    display: flex;
    flex-direction: row;
    justify-content:flex-start; 
}

.back{
    background-image: url("../../../vizr_images/pattern2.svg");
}

.platformSelected{
    background-color: #292929;
}
.initialSetUp_platformSelection_leftContainer{
    height: 100%;
    min-width: 310px;
    max-width: 310px;
    margin-right: 2rem;
    background-color: #ffffff;
    box-shadow: 0 6px 8px 0 rgba(0,0,0,0.2);
 
}
.platformWrapper {
    height: 53px;
    width: 100%;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.2);
 }
 .platformWrapper:hover{
    background-color: #706F7E;
    height: 53px;
    width: 100%;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.2);
  }
  .platformWrapperSelected {
    color:#ffffff !important;
    background-color: #706F7E !important;
    height: 53px;
    width: 100%;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.2);
 }
 .platformWrapperSelected:hover{
    color:#ffffff !important;
    background-color: #706F7E !important;
    height: 53px;
    width: 100%;
    box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.2);
  }
  
.wrapper {
    .MuiTypography-body1, .MuiTableCell-root, .MuiTypography-body2, .MuiTypography-h6, .MuiButton-root {
        font-family: Nunito-Regular;
    }
    .MuiTab-root{
        font-family: Nunito-SemiBold;
    }
}

.MuiMenuItem-root {
    font-family: Nunito-Regular !important;
}

.MuiTypography-body1 {
    font-family: Nunito-Regular !important;
}

text {
    font-family: Nunito-Regular !important;
}


.main-dashboard-container {
    .MuiGrid-root.MuiGrid-item button.MuiButtonBase-root.MuiIconButton-root {
        opacity: 0;
    }
    .MuiGrid-root.MuiGrid-item:hover button.MuiButtonBase-root.MuiIconButton-root {
        opacity: 1;
    }
} 

// @media  (min-width: 700px) and (max-width:1056px){
//     .platformInstructions_content {
     
    
   
//      }   
//   }

  @media  (min-width: 1000px) and (max-width:1510px){
    .platformInstructions_content {
        min-width: 76%;
     }   
  }
// @media  (min-width: 1056px) and (max-width:1200px){
//     .platformInstructions_content {
        
    
   
//      }   
//   }
  
//   @media  (min-width: 1201px) and (max-width:1350px) {
//     .platformInstructions_content {
     
//     }   
//   }

  
//   @media  (min-width: 1351px) and (max-width:1530px) {
//     .platformInstructions_content {
//         max-width:90%;
    
//     }   
//   }

// @media (min-width: 1451px) and (max-width:1650px) {
//     .platformInstructions_content {
     
    
//     }   
//   }

//   @media screen and (min-width: 1651px) {
//     .platformInstructions_content {
    
    
//     }   
//   }