.UserManagement{
    
    
    .description {
        height: 105px;
        font-family: Nunito-Regular;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 24px;
        padding-left: 210px;
        background: linear-gradient(rgba(241, 242, 246, 0), #dfe1e9);
    }

    #Roles_Popper{
        height: 296px;
        width: 255px;
        background-color: red;
        box-shadow: 0 6px 8px 0 rgba(0,0,0,0.2);
        display: flex !important;
        flex-direction: column !important;
    }
}