#incidentDonutChart{
    width: 100%;
    height: 330px
}

#AuthenticationFailedSourcePieChart{
    width: 105%;
   height: 21rem;
}

#AuthenticationFailedIpPieChart{
    width: 100%;
    height: 21rem;

  
}

#AgentsbyEnvironment{
    width: 100%;
    height: 18rem; 
  }

#AgentsSecurityToolState{
    width: 100%;
    height: 18rem; 

}
#AgentByOSVersion{
    width: 100%;
    height: 18rem;    

}

#patchMonitoringBySubsCriptionName{
    width: 100%;
    height: 25rem;
}

#patchesNeedBySeverity{
    width: 100%;
    height: 23rem
}

