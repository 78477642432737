//Color system

$pale-grey:        #dfe1e9;
$white:            #ffffff;
$pale-grey-0:      rgba(241, 242, 246, 0);
$white-two:        #f9f9f9;
$purplish-grey:    #706f7e;
$royal:            #081981;
$black:            #292929;
$white-three:      #e8e8e8;
$weird-green:      #2ce68f;
$aqua:             #20ddd7;
$cobalt:           #1a2983;
$clear-blue:       #296bfd;
$light-gold:       #ffce5e;
$tangerine:        #ff8421;
$pinkish-grey:     #c0c0c0;
$black-two:        #1a1a1a;
$reddish-pink:     #ff2251;
$baby-blue:        #a8d5ff;
$light-royal-blue: #2947fd;
$black-three:      #000000;
$powder-blue:      #bdd9ff;
$dark-brown-0:     rgba(65, 33, 8, 0);
$dark-brown:       #361b06;
$dark-brown-0-two: rgba(53, 28, 7, 0);
$dark-brown-66:    rgba(51, 27, 7, 0.66);
$dark-gray:        #9A9A9A ;
$tinted-gray:      #c5c5cb;


$default:       #344675 !default;
$primary:       #e14eca !default;
$secondary:     #f4f5f7 !default;
$success:       #00f2c3 !default;
$info:          #1d8cf8 !default;
$warning:       #ff8d72 !default;
$danger:        #fd5d93 !default;
$black:         #222a42 !default;

//Fonts

$font-family-base:            Nunito-Regular !default;
$font-family-alt:             'Nunito', sans-serif !default;

$font-size-dash:              .875rem !default;//14px

$font-size-base:              $font-size-dash !default; // Assumes the browser default, typically `16px`
$font-size-xl:                ($font-size-base * 1.428);
$font-size-lg:                ($font-size-base * 1.142);
$font-size-sm:                ($font-size-base * .85714286);
$font-size-xs:                ($font-size-base * .714);

$h1-font-size:                $font-size-base * 2.35714285 !default;  //2.1875rem
$h2-font-size:                $font-size-base * 1.9285714 !default;
$h3-font-size:                $font-size-base * 1.642857 !default;
$h4-font-size:                $font-size-base * 1.2142857 !default;
$h5-font-size:                $font-size-base * 0.9285714 !default;
$h6-font-size:                $font-size-base * 0.8571428 !default;

//Page Headings
$page-header-color:             $black;
$page-header-size:              32px !default;
$page-header-letter-spacing:    0;
$page-header-line-height:       40px;

$min-margin:                    8px;



