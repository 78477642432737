.userEditing_MainContainer{
    margin-top: 3%;
    padding-top: 3%;
    padding-left: 10%;
    margin-left: 13rem;
    min-height: 709px;
    height:100%;
    width:80%;
    border-radius: 4px;
    background-color:#FFFFFF;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.3);
    padding-bottom: 5rem;
  }