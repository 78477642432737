  .view_by {
    color: black;
    line-height: 40px;
  }

  .error_msg {
    color: red;
    font-size: 15px;
  }

  .dialog_content {
    height: auto;
    overflow: hidden;
  }

  .user-label {
    padding-right: 11px;
  }

  .dialog-header {
    float: right;
  }

  .user_count_txt {
    width: 20%;
    input {
      padding-top: 5px;
      padding-bottom: 5px
    }
  }