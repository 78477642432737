.generate_report_content {
  margin-top: 2%;
  height: 100%;
  width: 82%;
  padding-left: 2%;

  &.rows_content {
    padding-top: 3%;
    .MuiOutlinedInput-input{
      padding: 10px 14px;
    }
  }
}

.generate_report_input {
  box-sizing: border-box;
  height: 30px;
  width: 278px;
  border: 1px solid #dfe1e9;
  border-radius: 4px;
  background-color: #ffffff;
  .inputContainer{
    padding-left: 10px;
  }
}

.generate_report_heading {
  margin-bottom: 10px;
  font-weight: 700;
  &.notify{
    margin-top: 24px;
  }
}

.number_input {
  width: 100% !important;
}

.generate_report_date_container {
  padding-left: 2%;
  padding-top: 3%;
}

.generate_report_date_range {
  display: flex;
  gap: 20px;
}

.generate_report_dates {
  display: flex;
  align-items: center;
  gap: 10px;
}

.generate_report_date_header {
  color: #292929;
  font-weight: 700;
  font-size: 16px;
}

.generate_report_date_selector {
  display: flex;
  align-items: center;
}

.arrow_drop_down {
  cursor: pointer;

  &.icon_clicked {
    fill: blue;
  }
}

.report_field_main_container {
  display: flex;
  gap: 200px;
  margin-left: 10px;
  margin-top: 50px;
}

.report_date_range {
  margin-bottom: 25px;
}

.report_check_box {
  gap: 15px;
  .MuiCheckbox-colorPrimary.Mui-checked{
    color: #081981;
  }
}

.second_container {
  margin-top: 2%;
}

.notification_info {
  color: #5B5B5B;
  font-size: 16px;
  font-weight: 700;
  &.email_info{
    width: 300px;
  }
  .noteHeader{
    color: #000;
  }
}

.notification_message{
  white-space: nowrap;
}

.generate_report_footer_container{
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.generate_report_footer{
  margin-top: 15px;
  word-spacing: 2px;
  margin-left: 15px;
}

.Generate-button-container .generate_report_button{
  color: #ffffff;
  background-color: #081981;
  margin-right: 10px;
  text-transform: none;
  margin-left: 12px;
  &:hover{
    background-color: #061368;
  }
  &.printButton{
    margin-top: 0 !important;
    font-size: 14px;
    font-weight: 700;
  }
}

.report_link{
  color: blue;
  cursor: pointer;
}

.report_titles{
  font-family: "Nunito-Regular";
  font-weight: 700;
  font-size: 14px;
  &.name{
    word-break: break-all;
    white-space: normal;
  }
  &.content {
    white-space: normal;
    text-transform: capitalize;
  }
  &.generateBy{
    text-transform: capitalize;
  }
}

.report_date {
  display: flex;
  gap: 10px;
}

.report_timeRange {
  display: flex;
  width: 100%;
  // overflow-x: scroll;
  gap: 10px;
}
.reportHistoryDrawer{
  width: 550px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  .reportHistoryHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .close-icon{
      padding: 4px;
      min-width: 24px;
    }
  }
  .reportHistoryTitle {
    font-size: 24px;
    font-weight: 700;
    margin: 10px 0;
  }
  .reportHistoryHeaderDetails{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
    font-size: 12px;
    line-height: 18px;
  }
  .reportHistoryFieldTitle{
    font-weight: 700;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .reportHistoryContent {
    text-transform: uppercase;
  }
  .separator {
    color: #AAA;
    opacity: 0.4;
  }
  .expiryDetails{
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px; 
  }
  .sharedHistoryTitle{
    font-size: 20px;
    font-weight: 700;
    padding: 10px;
  }
  .sharedHistoryTable{
    flex-shrink: 1;
    overflow-y: auto;
    border: 1px solid #DEDEDE;
    box-shadow: 0px 0px 16px rgba(128, 128, 128, 0.15);
    padding: 10px 0px;
    border-radius: 4px;
  }
  .sharedHistoryDetails{
    display: flex;
    align-items: center;
    gap: 12%;
    font-size: 12px;
    font-weight: 700;
    padding: 15px 20px;
  }
  .sharedHistoryEmail{
    display: flex;
    align-items: center;
    gap: 20px;
    &.sharedEmail{
      width: 60%;
    }
  }
  .sharedHistoryTableHead{
    display: flex;
    align-items: center;
    gap: 58%;
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: 700;
    padding: 0 15px;
    padding-left: 25px;
  }
}

.reportRepositoryPopover{
  .MuiPopover-paper {
    margin-left: 10px;
  }
}
.viewHistoryPopup{
  border: 2px solid #081981;
  border-radius: 4px;
}
.popup-content{
  height: 90px;
  width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  .popup-images{
    &.Success {
      cursor: pointer;
    }
    &.viewIcon{
      padding: 2px;
      margin-top: 7px;
    }
  }
}

.report_pages{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.report_table {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px; 
}

.repositorySearch{
  margin: 0 70px 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reportRepositoryHeader{
  margin-bottom: 33px;
}
.shareButton{
  .MuiButton-contained{
    background-color: #fff;
  }
  .MuiButton-root{
    min-width: 30px;
  }
}
.reportSearchBar{
  width: 420px;
  height: 32px;
  border: 1px solid #DEDEDE;
  box-shadow:  0px 5px 8px -3px rgba(1, 18, 121, 0.50);
  display: flex;
  align-items: center;
  border-radius: 4px;
  .MuiButtonBase-root{
    min-width: 16px;
    padding: 0;
  }
  hr{
    height: 20px;
    border: none;
    border-left: 1px solid #706F7D;
  }
}
.searchCancelIcon{
  img{
    width: 16px;
    padding: 0 15px;
  }
}
.searchIcon{
  img{
    width: 18px;
    padding: 0 15px;
  }
}
.noSharedData{
  text-align: center;
}
// /* For Webkit-based browsers (e.g., Chrome) */
// ::-webkit-scrollbar {
//   width: 10px;
//   height: 0px;
// }
.shareReportDialog {
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  .MuiDialog-paper {
    color: #000;
    width: 540px;
    padding: 20px 6px;
    border: 2px solid #282E83;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.30);
    border-radius: 4px;
  }
}
.shareReportInput{
  .MuiInputBase-root{
    width: 360px;
    padding: 5px !important;
  }
}
.closeShareReport{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .close-icon{
    padding: 2px;
    min-width: 16px;
    img{
      width: 16px;
    }
  }
}
.shareReportMessage{
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 22px;
  &.success{
    color: green;
  }
  &.error{
    color: red;
  }
}
.shareReportErrorMessage{
  color: red;
}
.reportErrorMessage{
  color: red;
  margin-top: 10px;
  padding: 0;
}
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10; 
  &.report-page{
    background-color: #ffffff;
  }
}
.chipContainer{
  max-height: 100px;
  overflow-y: auto;
}
.printContainer{
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 20px 0px;
}
.printShareContainer{
  display: flex;
  justify-content: end;
}
@media print{
  .printShareContainer{
    display: none;
  }
}
.noReportFound{
  word-spacing: 6px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: gray;
  font-size: larger;
  text-transform: uppercase;
}
.newReportLink{
  display: flex;
  align-items: center;
  gap: 14px;
}
.notificationContainer{
  padding-left: 25px;
}
.generateReportHeadingContainer {
  display: grid;

  h2 {
    margin: 0;
    font-size: 32px;
  }

  p {
    margin: 0;
  }
}
.generatedBy{
  display: flex;
  align-items: center;
  gap: 10px;
}
.report-status{
   color: #edb640;
  &.Success{
    color: green;
  }
  &.Failure{
    color: red;
  }
}
.report-Icon-container {
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.12);
  width: 100%;
}
.expiryDetailsColorOrange{
  color: #FF8421;
}
.expiryDetailsColorRed{
  color: red;
}