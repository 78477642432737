.navbar {
    padding: 10px 10px;
    display: initial;
    // .photo {
    //   display: inline-block;
    //   height: 30px;
    //   width: 30px;
    //   border-radius: 50%;
    //   vertical-align: middle;
    //   overflow: hidden;
    //     img{
    //       width: 100%;
    //     }
    // }
    &.shrunk{
      padding: 35px 20px !important;
    }
  
    .navbar-wrapper {
      display: flex;
      align-items: center;
    }
  
    .navbar-text {
      color: white;
    }
  
    .btn{
      margin: 0 5px 0 10px;
    }

    a.nav-link{
      display: flex;
      height: 100%;
      padding-top: 14px;
      align-items: center;
    }
  
    &.navbar-absolute{
      position: absolute;
      z-index: 1050;
    }
  
  
    &.navbar-transparent{
      background: transparent !important;
    }
  
    
    .navbar-brand {
      position: fixed;
      padding-top: .3125rem;
      padding-bottom: .3125rem;
      color: $white;
      margin-left: 17px;
      margin-top: 3px;
      text-transform: uppercase;
      font-size: medium;
    }
  
    .navbar-toggle button:focus, .navbar-toggler{
      outline: none;
    }
  
  }

  
  .navbar-minimize-fixed {
      position: fixed;
    margin-left: 40px;
    margin-top: 14px;
      transition: 0.3s ease;
      color: white;
      z-index: 20;
      opacity: 0;
      transition: 0.2s ease;
      button{
          i {
              font-size: 20px;
          }
      }
  }
  
  .notification {
    background: red;
      color: white;;
      height: 6px;
      width: 6px;
      position: absolute;
      text-align: center;
      font-size: 12px;
      font-weight: 800;
    top: 10px;
    right: 10px;
      border: 1px solid red;
  }
  
  .navbar-nav li{
    padding: 0 10px;
    a{
      color: white;
    }
    i{
      vertical-align: middle;
      font-size: 20px;
    }
  }

  .dateRange-time-picker {
    width: 700px;
    height: 480px;
    padding: 15px 15px 15px 0;

    .dateRanger-header {
      font-size: 18px;
      padding: 0px 0 30px 20px;
      .select-range {
          margin-left: 156px;
      }
    }

    .rdrCalendarWrapper.rdrDateRangeWrapper {
      position: relative;
      left: 60px;
    }

    .time-range-container {
      padding-bottom: 15px;
      .time-range-picker span.range-text {
        position: relative;
        top: 12px;;
      }
    }

    .timepicker-div {
      width: 80px;
      button {
        display: none;
      }

      .MuiInput-underline:before, 
      .MuiInput-underline:after,
      .MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: none;
      }

      input.MuiInput-input{
        height: 32px;
        width: 80px;
        border-radius: 4px;
        background-color: #FFFFFF;
        box-shadow: 0 5px 8px -3px rgba(1, 18, 121,0.5);
        padding-left: 10px;
      }
      p.MuiFormHelperText-root.MuiFormHelperText-filled {
        display: none;
      }
    }
}


.dateRange-search-box {
  span.selected-date-value {
    border-radius: 1px;
    background-color: #DFE1E9;
    color: #081981;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    padding: 4px 14px;
    display: inline-block;
    margin: 0 5px;
}

input.MuiInputBase-input {
    color: #081981;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 5px;
    width: 240px !important;
    padding-left: 8px;
}

}

.app-timezone-box {
  max-height: 32px;
  margin: 0 15px;
  width: 160px;
  border-radius: 4px;
  background-color: #FFFFFF;
  box-shadow: 0 5px 8px -3px rgba(1,18,121,0.5);
  color: #081981;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  .MuiFormControl-marginNormal {
    margin: 0;
  }
  .MuiInput-underline:before {
    border: none;
  }
  input {
    font-size: 14px;
    margin-left: 10px;
  }
  button {
    padding: 3px 0 0 0;
  }
}






