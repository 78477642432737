.accessDetailsRow_Lesswidth{
    width:120px;
}

.accessAnalomy_TableRow{
    font-family: Nunito-Regular !important;
}
.allAccessRow{
    font-family: Nunito-Regular !important;
}
.accessDetailsRow{
    font-family: Nunito-Regular !important;
}
.datasourceDetails_TableRow{
    font-family: Nunito-Regular !important;
    width: 141px;
}
.accessAnalomy_Severity{
    width: max-content !important;
    height: 30px;
    padding: 5px 6px 0px 7px;
    border-radius: 2px;

    
}
@mixin SEVERITY($BGcolor,$color)
{
    color: $color;
    background-color: $BGcolor;
}
.accessAnalomy_Severity.High{
    @include  SEVERITY(#ffc5d1,#ff2251)
  
}

.accessAnalomy_Severity.Medium{
    @include  SEVERITY(#ffd7b6,#ff8421)
}



.sensitiveAccess{
    width: max-content !important;
    height: 0.80rem;
    padding: 10px 5px 10px 7px;
    border-radius: 2px;
    margin:5px 5px;

  
}
.sensitiveAccess.HIGH{
    @include  SEVERITY(#ffc5d1,#ff2251);
    margin-right:2rem;
}

.sensitiveAccess.MEDIUM{
    @include  SEVERITY(#ffd7b6,#ff8421);
    margin-right: 2rem;
}

.MDRDashboard_PatchMonitoring{
    width:5% !important;
    &.lessWidth{
        max-width: 5%;
        white-space: initial;
        word-wrap: break-word;
    }
    &.extrawidth{
        width:100% !important
    }
}


.mdrIncidentTableSeverityColumn{
    width: max-content !important;
    height: 0.80rem;
    padding: 10px 5px 10px 7px;
    border-radius: 4px;
    margin:5px 5px;

  
}

.mdrIncidentTableSeverityColumn.critical{
    @include  SEVERITY(#ff2251,white);
    margin-right:2rem;
}

.mdrIncidentTableSeverityColumn.high{
    @include  SEVERITY(#ffc5d1,black);
    margin-right:2rem;
}

.mdrIncidentTableSeverityColumn.medium{
    @include  SEVERITY(#ffd7b6,#ff8421);
    margin-right: 2rem;
}


.mdrIncidentTableSeverityColumn.low{
    @include  SEVERITY(#d2deeb,#0021f5);
    margin-right: 2rem;
}
.accessDetailsRow{
    width: max-content !important;
    height: 0.80rem;
    padding: 7px 5px 10px 7px;
    border-radius: 2px;
    margin:5px 5px;
}
.accessDetailsRow.HIGH{
    @include  SEVERITY(#ffc5d1,#ff2251)
  
}

.accessDetailsRow.MEDIUM{
    @include  SEVERITY(#ffd7b6,#ff8421)
}


.accessDetailsRow.LOW{
    @include  SEVERITY(#d2deeb,#0021f5)
}
