.MDRSettings {
  .description {
    font-family: Nunito-Regular;
    display: flex;
    justify-content: space-between;
    margin: 0 0 24px;
    padding: 24px 24px 0px 210px;
    background: linear-gradient(rgba(241, 242, 246, 0), #dfe1e9);
  }
}

.managementFooter.MDRsettingsFooter {
  width: 33%;
  margin-left: 13rem;

  .managementDone {
    background-color: #8014bd !important;
  }
  .ddvManagementDone {
    height: 2.75rem;
    width: 9.125rem;
    border-radius: 4px;
    background-color: #2947fd;
    box-shadow: 0 5px 8px -3px rgba(41, 71, 253, 0.5);
    cursor: pointer;
    color: white;
    text-align: center;
    padding-top: 2.5%;
    margin-right: 14%;
  }
}

.MDRSettings_TabsParent {
  // margin-left: 14rem;
  display: flex;
  // justify-content: space-between;
}

.MDRSettings_Tabs {
  height: 3.2rem;
  width: 45rem;
  margin-left: 14rem;
  text-align: center;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
  border-radius: 22px;
  display: flex;
  cursor: pointer;

  .activeTab {
    color: #081981;
    //  color: #706F7E;
    font-family: "Nunito-Regular", sans-serif;
    font-weight: 600;
    height: 82%;
    width: 100%;
    border-radius: 22px;
    box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
    padding-top: 1.3%;
  }
  .InactiveTab {
    padding-top: 1.5%;
    // height: 2rem;
    height: 85%;
    width: 90%;
    color: #706f7e;
    // color:blue;
    font-family: "Nunito-Regular", sans-serif;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    border-radius: 22px;
    // box-shadow: 0 5px 8px -3px rgba(1,18,121,0.5);
  }
}

.filter-table-container {
  display: flex;
  margin: 24px 24px 12px 211px;
  // font-family: $font-family-base;
}
.table-container {
  // font-family: $font-family-base;
  width: 100%;
  border-radius: 4px;
  // box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  background-color: white;
}
.MDRInputTextFeildAddConfig {
  box-sizing: border-box;
  height: 2rem;
  min-width: 15%;
  width: 90%;
  border: 1px solid #dfe1e9;
  border-radius: 4px;
  background-color: #ffffff;
  padding-left: 10px;
}
.MDRInputTextFeildAddConfig:focus {
  box-sizing: border-box;
  height: 2rem;
  border: 1px solid grey !important;
  border-bottom: 1px solid grey !important;
  border-radius: 4px;
  outline: none;
}

.MDR_SettingsContent_Container {
  border: 1px solid lavender !important;
  min-height: 50rem;
  height: 100%;
  width: 83%;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  margin-left: 13rem;
  margin-top: 2rem;
  position: relative;

  .MDRInputField_flex {
    // margin-left: 8% !important;
    padding-bottom: 25px;
  }
}

.MDRinputFieldNumber_container {
  height: 35px;
  width: 35px;
  background-color: #dfe1ea;
  text-align: center;
  border-radius: 50px;
  margin-right: 12%;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.MDRinputFieldNumber {
  color: #292929;
  font-family: Nunito-ExtraBold;
  font-size: 16px;
}
.ManagementContent_Container {
  .onboarding_text {
    height: 20px;
    width: 97%;
    color: #292929;
    font-family: Nunito;
    font-size: 15px;
    letter-spacing: 0;
    // line-height: 20px;
  }
} 

.cursorPointer{
  cursor: pointer;
}
.addSubnetButton.MuiButton-root {
  color: rgb(255, 255, 255);
  background-color: rgb(128, 20, 189);
  font-size: 14px;
  font-weight: bold;
  text-transform: none;
  padding: 4px 30px;
  box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
  cursor: pointer;
  border-radius: 6px;
  line-height: 1.5;

  &.ddv.MuiButton-root {
    background-color: #081981;
  }
}
