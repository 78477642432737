.trigger-container{
    box-sizing: border-box;
    height: fit-content;
    margin-top: 16px ;
    margin-bottom: 16px;
    padding: 10px;
    border: 1px dashed rgba(11,11,244,0.23);
    border-radius: 7px;
    background-color: rgba(152,196,255,0.12);
    .MuiToggleButtonGroup-root{
        width: -webkit-fill-available;
    }
    &.error{
        border: 1px dashed red;
    }
    &.textHeading {
        font-weight: 600;
    }
}

.MuiToggleButtonGroup-groupedHorizontal:not(:first-child){
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    margin-left: 10px !important;
}
.MuiToggleButtonGroup-groupedHorizontal:not(:last-child){
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}
.MuiToggleButton-label{
    //width: 0 !important;
}
.MuiFormControlLabel-labelPlacementStart{
    margin-left: 67% !important;
}
.thresholdInput {
    width: 70%;
    color: #292929;
    height: 30px;
    border: none;
    box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
    background-color: white;
    text-align: left;
    margin: 10px 0px;
}
.min-traces-value{
    color: #081981;
    font-size: 16px;
    font-weight: 600;
    float: right;
}
.header-font{
    font-size: 14px;
}
.divider{
    margin: 20px 0 20px 0;
    background-color: #979797 !important;
}