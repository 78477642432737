@import "./variables";

h3{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  //font-family: 'Nunito Sans', sans-serif !important;
}

.bg1{
  height: 75px;
  width: 151px;
  margin-bottom: 50px;
  position: relative;
  top:41px;
  right:-74%;
 
}
.bg2{
  position: absolute;
  height: 626px;
  width: 540px;
  opacity:1;
  z-index: -1;
  top:80px;
  left:-175px;
  transform: rotate(355deg)
}
.bg3{
  position: absolute;
  height: 626px;
  width: 450px;
  opacity:1;
  z-index: -1;
  top:300px;
  right:-5rem;
  // transform: rotate(375deg);
}
.bg4{
  height: 75px;
  width: 151px;
  position: absolute;
  bottom:-55%;
  left:12%;
}
.login_page{
  min-height: 100vh;
  width:100%;
  height:100%;
  // margin-top: 10rem;
  // margin-bottom: 10rem;

  // background-image: url("../../../vizr_images/combinedBackground.svg");
  // background-position: 75% 10%;
}
.login_pageConatainer{
  width: 82% !important;
  min-height: 42rem !important;
  max-height:250%;
  border-radius: 33px;
  margin-left: 10rem;
  font-family: "Nunito-Regular !important";
  margin: auto;
  align-items:flex-start !important;
  opacity: 1;
  box-shadow: 0 15px 50px 11px rgba(0,0,0,0.15);
  background-color:$white;
  position: relative;
  
}


.login_pageConatainerForm{

   background-color:$white;
  &-logo{
    height: 6.25rem !important;
    width: 8.8rem !important;
    margin:6% 100% 4% 40%;
  }
 }





// .inputContainer{
//     margin: 6% 15%;
// }

.email-input{
    border:1px solid $pale-grey;
    border-radius: 3px;
    // width:calc(100% - 0.5rem);
     width:100%;
     margin-top: 1%;
     margin-bottom: 1rem;
     padding-left: 1rem;
    &:focus{
      border:1px solid $purplish-grey;
    }
    &:hover{
      border:1px solid $light-royal-blue;
    }
  } 
  .forgotpasswordInput{
    border:1px solid $pale-grey;
    border-radius: 3px;
    width:100%;

    &:focus{
      border:1px solid $purplish-grey;
    }
    &:hover{
      border:1px solid $light-royal-blue;
    }
  } 
  .margin{
    margin-top: 7%;
    height: 1rem;
    width: 10rem;
    color: #000000;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1rem;
  }
  .email-inputError{
    border:1px solid $reddish-pink;
    border-radius: 3px;
  }
  
.signin-Button{
  text-transform: none !important;
  width: 70% !important;
  font-family:"Nunito-Regular !important";
  background-color: $light-royal-blue !important;
  font-weight: bold !important;
  margin-bottom: 1.5rem !important;
  margin-top: 1rem !important;
}
.signuppage_NotaMember{
  height: 12px;
  width: 175px;
  color: #292929;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 12px;
}

.login_pageConatainerForm_signup{
  color: $light-royal-blue;
  text-decoration: underline;
}
.login_pageConatainerForm_signup:hover{
  color: $light-royal-blue !important;
  text-decoration: underline !important;
}
.login_pageConatainerForm_forgotpassword{
  color: $light-royal-blue;
  font-family: "Nunito-Regular";
  text-decoration: none;
}
.login_pageConatainerForm_forgotpassword:hover{
  color: $light-royal-blue;
}
.login_pageConatainerForm_forgotpassword_link{
  display: flex;
  justify-content: flex-end;
}