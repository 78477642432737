.SetPasswordContainer{
    min-height: 811px;
    height:100%;
    width: calc(82% - 2em);
    margin-top: 2rem;
    margin-left: 14rem;
    border-radius: 4px;
    background-color: #FFFFFF;
    box-shadow:  0 1px 2px 0 rgba(0,0,0,0.3);
    // display: flex;
    // flex-direction:  column;
    // align-items:  center;
    background-image: url("../../../assets/vizr_images/combinedBackground.svg");
    background-position: 53% 45%;
}