.VerifyEmail_Container{

    width:100% !important;
    .centralContainer{
        min-height: 400px;
        max-height: 100%;
        // width:82%;
        min-width: 25%;
        width:65%;
        border-radius: 4px;
        background-color: #ffffff;
        box-shadow:0 1px 2px 0 rgba(0,0,0,0.3);
        margin-left: 13rem;
        margin-top: 3%;
        padding: 10%;
        color: #111;
        font-size: 1.6rem;
        letter-spacing: 0;
        line-height: 24px;
        text-align: center;
        background-image: url("../../../assets/vizr_images/combinedBackground.svg");
        background-repeat: no-repeat;
        background-position: -33rem -5rem;
    }
}