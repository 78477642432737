.GrovernorNavToolBar{
  background: #1282b3;
}
.shrunk-sidebar{
  &.Governor_bgimg_shrunk{
    background-color: #1282b3;
    width: 68px;
    height: 1000px;
   }
}
.governor-container {
  .first-cell-mdr{
    background-color: #1282b3 !important;
  }
  .last-cell-mdr{
    background-color: #1282b3 !important;
  }
  .other-cell-mdr{
    background-color: #1282b3 !important;
  }
  .managementDone {
    background-color: #1282b3 !important;
  }
}

.last-updated-styles{
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
}