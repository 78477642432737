.enhanced-grid {
  .MuiTableContainer-root {
    max-height: 450px;
  }
  &.report {
    .MuiTableContainer-root {
       max-height: 550px;
    }
  }
  .table {
    .MuiCheckbox-root {
      color: rgba(0, 0, 0, 0.54);
  }
    .list-deleted {
      cursor:not-allowed;
      .deleted{
        pointer-events: none;
      }
    }
    .MuiTableCell-paddingCheckbox {
      width: 48px;
      padding: 0 0 0 4px;
      position: sticky;
    }
    .MuiTableRow-root.Mui-selected,
    .MuiTableRow-root.Mui-selected:hover {
      background-color: rgba(245, 0, 87, 0.08);
    }
    .grid-empty-container {
      width: 100%;
      height: 40px;
      text-align: center;
      .grid-empty-message {
        padding: 10px 0px;
        font-size: 14px;
      }
    }
    .mdr-other-cell
    {
      color: #ffffff !important;
      background-color: rgb(41, 153, 202);
    }
    .other-cell
    {
      color: #ffffff !important;
      background-color: #2947fd !important;
      
    }
  }
}

.riskTableContainer.MuiTableContainer-root {
  max-height: max-content;
}

.riskTableContainer{
  .MuiTableCell-root {
    line-height: normal;
    padding: 12px;
  }
}

.loading {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.loading:after {
  overflow: hidden;
  display: inline-block;
  vertical-align: bottom;
  -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
  animation: ellipsis steps(4,end) 900ms infinite;
  content: "\2026"; /* ascii code for the ellipsis character */
  width: 0px;
}

.logs-count {
  margin-top: 4px;
  font-size: 20px;
}

.total-count {
  text-decoration: none;
  color: blue;
  cursor: pointer;
}

@keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;    
  }
}

.show-exec-container {
  text-align: center;
  cursor: pointer;
}
.rowClassName{
  height: 92px;
}