.customDate {
  background-color: #dfe1e9 !important;
  color: black !important;
  border-radius: 6px;
  margin-left: 4rem;
  width: 100%;
}

.calenderContainer .rdrDayPassive .rdrDayNumber {
  color: red !important;
  background-color: #dfe1e9;
  // background-color: red;
}

.database-logs-access-picker {
    height: 480px;
    overflow: auto;
  .rdrDateRangeWrapper {
    background-color: #dfe1e9;
    border-radius: 5px;
  }

  .rdrWeekDays {
    border-bottom: 1.3px solid #081981;
  }
  .rdrDays {
    button {
      margin: 0px;
    }
  }
  .header-text {
    font-family: Nunito-Bold;
    font-size: 18px;
    padding: 7px 0px;
  }

  .time-range-container {
    padding-bottom: 15px;
    .time-range-picker span.range-text {
      position: relative;
      top: 12px;
      left: 7px;
    }
  }
  .rdrSelected,
  .rdrInRange {
    background: #c9ceeb;
  }
  .rdrDay.rdrDayHovered {
    border: transparent;
  }
  .rdrSelected,
  .rdrStartEdge,
  .rdrEndEdge {
    background: #2d48fd;
    border-radius: 50%;
  }

  .rdrDayStartPreview,
  .rdrDayInPreview,
  .rdrDayEndPreview {
    border: transparent;
  }

  .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
  .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
    color: black;
  }
  .week{
    display: inline-block;
    width: 33%;
    cursor: pointer;
    padding: 5px;
    box-sizing: border-box;
  }
  .month{
    display: inline-block;
    width: 34%;
    cursor: pointer;
    padding: 5px;
    box-sizing: border-box;
  }

  .quick-range-heading{
    font-family: Nunito-Bold;
    font-size: 18px;
    padding:10px 0px;
  }
  
  .timepicker-div {
    width: 80px;
    button {
      display: none;
    }
    .MuiInputBase-root{
      pointer-events: none;
    }
    .MuiInput-underline:before,
    .MuiInput-underline:after,
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
    input.MuiInput-input {
      height: 32px;
      width:102px;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
      padding-left: 3px;
      color: #2d48fd;
    }
    p.MuiFormHelperText-root.MuiFormHelperText-filled {
      display: none;
    }
  }

  .timepicker-div_timeRange {
    width: 80px;
    button {
      display: none;
    }
    .MuiInput-underline:before,
    .MuiInput-underline:after,
    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none;
    }
    input.MuiInput-input {
      height: 32px;
      width:102px;
      border-radius: 4px;
      background-color: #ffffff;
      box-shadow: 0 5px 8px -3px rgba(1, 18, 121, 0.5);
      padding-left: 3px;
      color: #2d48fd;
    }
    p.MuiFormHelperText-root.MuiFormHelperText-filled {
      display: none;
    }
  }
}
.filter{
  .MuiAccordionSummary-content{
    justify-content: space-between;
  }
  .MuiAccordion-root:before{
    background-color:transparent;
  }
}
