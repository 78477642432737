.IntegrationsHealth_Container{
    margin-top: 4rem;
}
.snack-status {
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  position: relative;
  width: 160px;

  &.success {
    background-color: #2E7D32;
  }

  &.not-connected {
    background-color: #DDD116;
  }

  &.error {
    background-color: #D32F2F;
  }

  &.mgt-style {
    margin-left: 10%;
  }
}